import React, { createContext, useEffect } from "react";
import "../Data_adding_page/adminPage.css";
import * as XLSX from "xlsx/xlsx.mjs";
import { useState, useContext } from "react";
import axios from "axios";
import FlipkartC from "../Sql_Template/FC.json";
import FlipkartFF from "../Sql_Template/FFF.json";
import FlipkartRSF from "../Sql_Template/FRSF.json";
import FlipkartSF from "../Sql_Template/FSF.json";
import MyntraC from "../Sql_Template/MC.json";
import MyntraFF from "../Sql_Template/MFF.json";
import MyntraFSF from "../Sql_Template/MFSF.json";
import MyntraRSF from "../Sql_Template/MRSF.json";
import Areferel from "../Sql_Template/ARF.json";
import Aclosing from "../Sql_Template/ACF.json";
import Ashipping from "../Sql_Template/ASF.json";
import MyntraSJITF from "../Sql_Template/MSJITF.json";
import MyntraSJITFR from "../Sql_Template/MSJITFR.json";
import MyntraSJITPP from "../Sql_Template/MSJITPP.json";
import Testing3 from "../aspfinder/flipkart/Testing3";
import ContextCreate from "../aspfinder/flipkart/Testing3";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const AdminPage = () => {
  const [fileName, setFileName] = useState("file not selected");
  const [file, setFile] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [flipkartCommission, setFlipkartCommission] = useState([]);
  const [myntraCommission, setMyntraCommission] = useState([]);
  const [amazonCommission, setAmazonCommission] = useState([]);
  const [drop1, setDrop1] = useState("");
  const [drop2, setDrop2] = useState([]);
  const [drop3, setDrop3] = useState("");
  const [drop4, setDrop4] = useState([]);
  const [brandF, setBrandF] = useState([]);
  const [click, setClick] = useState(true);
  const [click2, setClick2] = useState(false);
  const [valueAU, setValueAU] = useState("");
  const [error, setError] = useState([]);
  const [command, setCommand] = useState({});
  console.log(command);
  if (Object.entries(command).length > 0) {
    console.log(command.data[0].message);
  }
  // console.log(error)
  const redux_state = useSelector((state) => state.counter);
  console.log(redux_state);
  const currentPageUrl = window.location.href;
  console.log(currentPageUrl);

  const uniqueVendorNames = [
    ...new Set(flipkartCommission.map((val) => val.Vendor_Name)),
  ];
  const MuniqueVendorNames = [
    ...new Set(myntraCommission.map((val) => val.Vendor_Name)),
  ];
  const AuniqueVendorNames = [
    ...new Set(amazonCommission.map((val) => val.Vendor_Name)),
  ];
  const uniqueBrandNames = [...new Set(drop2.map((val) => val.Brand_Name))];
  const MuniqueBrandNames = [...new Set(drop2.map((val) => val.Brand_Name))];
  const AuniqueBrandNames = [...new Set(drop2.map((val) => val.Brand_Name))];

  const uniqueCategoryNames = [
    ...new Set(drop4.map((val) => val.Sub_category)),
  ];
  const MuniqueCategoryNames = [
    ...new Set(drop4.map((val) => val.Sub_category)),
  ];
  const uniqueGenderNames = [...new Set(drop4.map((val) => val.Gender))];
  const MuniqueGenderNames = [...new Set(drop4.map((val) => val.Gender))];

  ///////////////
  const uniqueCategoryNames2 = [
    ...new Set(drop2.map((val) => val.Sub_category)),
  ];
  const MuniqueCategoryNames2 = [
    ...new Set(drop2.map((val) => val.Sub_category)),
  ];
  const uniqueGenderNames2 = [...new Set(drop2.map((val) => val.Gender))];
  const MuniqueGenderNames2 = [...new Set(drop2.map((val) => val.Gender))];

  ///////////////
  const uniqueBrandNames3 = [
    ...new Set(flipkartCommission.map((val) => val.Brand_Name)),
  ];
  const MuniqueBrandNames3 = [
    ...new Set(myntraCommission.map((val) => val.Brand_Name)),
  ];
  const uniqueCategoryNames3 = [
    ...new Set(flipkartCommission.map((val) => val.Sub_category)),
  ];
  const MuniqueCategoryNames3 = [
    ...new Set(myntraCommission.map((val) => val.Sub_category)),
  ];
  const uniqueGenderNames3 = [
    ...new Set(flipkartCommission.map((val) => val.Gender)),
  ];
  const MuniqueGenderNames3 = [
    ...new Set(myntraCommission.map((val) => val.Gender)),
  ];

  const downloadTemplate = () => {
    // if (!click2) {
    //   downloadTemplate2();
    // } else {
    if (
      selectedValue == "brandfortunes.MyntraSJIT_fixedfees" ||
      selectedValue == "brandfortunes.MyntraSJIT_forward_reverse_fee" ||
      selectedValue == "brandfortunes.myntraSJIT_pick_and_pack_fee"
    ) {
      templateDownload();
    } else {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const worksheet2 = workbook.addWorksheet("Sheet 2");

      const template =
        selectedValue == "brandfortunes.Flipkart2_commission"
          ? FlipkartC
          : selectedValue == "brandfortunes.Flipkart2_fixedFees"
          ? FlipkartFF
          : selectedValue == "brandfortunes.Flipkart2_ReverseShippingFees"
          ? FlipkartRSF
          : selectedValue == "brandfortunes.Flipkart2_ShippingFees"
          ? FlipkartSF
          : selectedValue == "brandfortunes.Myntra2_Commission"
          ? MyntraC
          : selectedValue == "brandfortunes.Myntra2_Fixedfees"
          ? MyntraFF
          : selectedValue == "brandfortunes.Myntra2_ForwardShippingCharges"
          ? MyntraFSF
          : selectedValue == "brandfortunes.Myntra2_ReverseShippingCharges"
          ? MyntraRSF
          : selectedValue == "brandfortunes.Amazon_shipping_fees"
          ? Ashipping
          : selectedValue == "brandfortunes.Amazon_referal_fees"
          ? Areferel
          : selectedValue == "brandfortunes.Amazon_closing_fees"
          ? Aclosing
          : null;

      if (!click) {
        worksheet.addRow(Object.keys(template[0]));
        const O = Object.values(template[0]).slice(2);
        const A = [drop1, drop3, ...O];
        worksheet.addRow(A);
      } else if (click) {
        worksheet.addRow(Object.keys(template[0]));
        const O = Object.values(template[0]).slice(1);
        const A = [drop1, ...O];
        worksheet.addRow(A);
      }

      // worksheet.addRow(['Jane Doe', 25, '']);
      const arrayLen = [
        uniqueBrandNames.length,
        uniqueCategoryNames.length,
        uniqueGenderNames.length,
        uniqueVendorNames.length,
      ];
      const arrayLen2 = [
        uniqueBrandNames.length,
        uniqueCategoryNames2.length,
        uniqueGenderNames2.length,
        uniqueVendorNames.length,
      ];
      const arrayLen3 = [
        uniqueBrandNames3.length,
        uniqueCategoryNames3.length,
        uniqueGenderNames3.length,
        uniqueVendorNames.length,
      ];

      const Bigno = Math.max(...arrayLen);
      const Bigno2 = Math.max(...arrayLen2);
      const Bigno3 = Math.max(...arrayLen3);
      console.log(arrayLen);
      console.log(Bigno);
      console.log(drop4);
      if (!click) {
        for (let i = 0; i < Bigno; i++) {
          // if (currentPageUrl ==="http://localhost:3000/Flipkart-add-data" || "https://brandfortunes.in/Flipkart-add-data") {
          worksheet2.addRow([uniqueCategoryNames[i], uniqueGenderNames[i]]);

          // }
          // if (currentPageUrl ==="http://localhost:3000/Myntra-add-data" || "https://brandfortunes.in/Myntra-add-data") {
          // worksheet2.addRow([MuniqueCategoryNames[i], MuniqueGenderNames[i]]);
          // }
        }
      } else if (click) {
        for (let i = 0; i < Bigno2; i++) {
          // if (currentPageUrl ==="http://localhost:3000/Flipkart-add-data" || "https://brandfortunes.in/Flipkart-add-data") {

          worksheet2.addRow([
            uniqueBrandNames[i],
            uniqueCategoryNames2[i],
            uniqueGenderNames2[i],
          ]);
          //  }
          // if (currentPageUrl ==="http://localhost:3000/Myntra-add-data" || "https://brandfortunes.in/Myntra-add-data") {

          //   worksheet2.addRow([
          //     MuniqueBrandNames[i],
          //     MuniqueCategoryNames2[i],
          //     MuniqueGenderNames2[i],
          //   ]); }
        }
      }

      // uniqueCategoryNames.map((val, index) => {
      //   worksheet2.addRow([val]);
      // });

      // uniqueGenderNames.map((val,index)=>{
      //   worksheet2.addRow([val])
      // })
      if (click) {
        for (let i = 2; i <= 250; i++) {
          const cell = worksheet.getCell(`B${i}`);
          cell.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`='Sheet 2'!$A$1:$A$${uniqueBrandNames.length}`],
          };
        }
        for (let i = 2; i <= 250; i++) {
          const cell2 = worksheet.getCell(`C${i}`);
          cell2.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`='Sheet 2'!$B$1:$B$${uniqueCategoryNames2.length}`],
          };
        }
        for (let i = 2; i <= 250; i++) {
          const cell3 = worksheet.getCell(`D${i}`);
          cell3.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`='Sheet 2'!$C$1:$C$${uniqueGenderNames2.length}`],
          };
        }
      } else if (!click) {
        for (let i = 2; i <= 250; i++) {
          const cell = worksheet.getCell(`C${i}`);
          cell.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`='Sheet 2'!$A$1:$A$${uniqueCategoryNames.length}`],
          };
        }
        for (let i = 2; i <= 250; i++) {
          const cell2 = worksheet.getCell(`D${i}`);
          cell2.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`='Sheet 2'!$B$1:$B$${uniqueGenderNames.length}`],
          };
        }
      }

      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "Template-file.xlsx");
        })
        .catch((err) => console.error("Error writing Excel file:", err));
    }
  };
  // };
  const downloadTemplate2 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const worksheet2 = workbook.addWorksheet("Sheet 2");
    const template =
      selectedValue == "brandfortunes.Flipkart2_commission"
        ? FlipkartC
        : selectedValue == "brandfortunes.Flipkart2_fixedFees"
        ? FlipkartFF
        : selectedValue == "brandfortunes.Flipkart2_ReverseShippingFees"
        ? FlipkartRSF
        : selectedValue == "brandfortunes.Flipkart2_ShippingFees"
        ? FlipkartSF
        : selectedValue == "brandfortunes.Myntra2_Commission"
        ? MyntraC
        : selectedValue == "brandfortunes.Myntra2_Fixedfees"
        ? MyntraFF
        : selectedValue == "brandfortunes.Myntra2_ForwardShippingCharges"
        ? MyntraFSF
        : selectedValue == "brandfortunes.Myntra2_ReverseShippingCharges"
        ? MyntraRSF
        : null;

    worksheet.addRow(Object.keys(template[0]));
    worksheet.addRow(Object.values(template[0]));

    // worksheet.addRow(['Jane Doe', 25, '']);

    if (
      currentPageUrl ===
      ("http://localhost:3000/Flipkart-add-data" ||
        "https://brandfortunes.in/Flipkart-add-data")
    ) {
      const arrayLen3 = [
        uniqueBrandNames3.length,
        uniqueCategoryNames3.length,
        uniqueGenderNames3.length,
        MuniqueVendorNames.length,
      ];

      const Bigno3 = Math.max(...arrayLen3);

      for (let i = 0; i < Bigno3; i++) {
        worksheet2.addRow([
          uniqueVendorNames[i],
          uniqueBrandNames3[i],
          uniqueCategoryNames3[i],
          uniqueGenderNames3[i],
        ]);
      }
    } else if (
      currentPageUrl ===
      ("http://localhost:3000/Myntra-add-data" ||
        "https://brandfortunes.in/Myntra-add-data" ||
        currentPageUrl === "https://brandfortunes.in/MyntraSJIT-add-data" ||
        currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data")
    ) {
      const arrayLen3 = [
        MuniqueBrandNames3.length,
        MuniqueCategoryNames3.length,
        MuniqueGenderNames3.length,
        MuniqueVendorNames.length,
      ];

      const Bigno3 = Math.max(...arrayLen3);

      for (let i = 0; i < Bigno3; i++) {
        worksheet2.addRow([
          MuniqueVendorNames[i],
          MuniqueBrandNames3[i],
          MuniqueCategoryNames3[i],
          MuniqueGenderNames3[i],
        ]);
      }
    }
    // uniqueCategoryNames.map((val, index) => {
    //   worksheet2.addRow([val]);
    // });

    // uniqueGenderNames.map((val,index)=>{
    //   worksheet2.addRow([val])
    // })

    for (let i = 2; i <= 250; i++) {
      const cell = worksheet.getCell(`A${i}`);
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`='Sheet 2'!$A$1:$A$${uniqueVendorNames.length}`],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell2 = worksheet.getCell(`B${i}`);
      cell2.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`='Sheet 2'!$B$1:$B$${uniqueBrandNames3.length}`],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell3 = worksheet.getCell(`C${i}`);
      cell3.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`='Sheet 2'!$C$1:$C$${uniqueCategoryNames3.length}`],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell4 = worksheet.getCell(`D${i}`);
      cell4.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`='Sheet 2'!$D$1:$D$${uniqueGenderNames3.length}`],
      };
    }

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template-file.xlsx");
      })
      .catch((err) => console.error("Error writing Excel file:", err));
  };

  useEffect(() => {
    async function fetchData4() {
      try {
        const response = await axios.get("/api/Registries");
        setFlipkartCommission(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData4();
  }, []);
  useEffect(() => {
    async function fetchData4() {
      try {
        const response = await axios.get("/api/MyntraRegistries");
        setMyntraCommission(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData4();
  }, []);
  useEffect(() => {
    async function fetchData4() {
      try {
        const response = await axios.get("/api/Areferalfess");
        setAmazonCommission(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData4();
  }, []);

  // const count = useSelector((state)=> state.counter)
  // console.log(count);

  const templateDownload = () => {
    const template =
      selectedValue == "brandfortunes.Flipkart2_commission"
        ? FlipkartC
        : selectedValue == "brandfortunes.Flipkart2_fixedFees"
        ? FlipkartFF
        : selectedValue == "brandfortunes.Flipkart2_ReverseShippingFees"
        ? FlipkartRSF
        : selectedValue == "brandfortunes.Flipkart2_ShippingFees"
        ? FlipkartSF
        : selectedValue == "brandfortunes.Myntra2_Commission"
        ? MyntraC
        : selectedValue == "brandfortunes.Myntra2_Fixedfees"
        ? MyntraFF
        : selectedValue == "brandfortunes.Myntra2_ForwardShippingCharges"
        ? MyntraFSF
        : selectedValue == "brandfortunes.Myntra2_ReverseShippingCharges"
        ? MyntraRSF
        : selectedValue == "brandfortunes.MyntraSJIT_fixedfees"
        ? MyntraSJITF
        : selectedValue == "brandfortunes.MyntraSJIT_forward_reverse_fee"
        ? MyntraSJITFR
        : selectedValue == "brandfortunes.myntraSJIT_pick_and_pack_fee"
        ? MyntraSJITPP
        : null;
    const worksheet = XLSX.utils.json_to_sheet(template);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "sample-file.xlsx");
  };

  // const select = (e) => {
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     console.log(parsedData);
  //   };
  // };
  const selectTable = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  };
  const handleChange = (event) => {
    if (event.target.value === "update") {
      setClick2(true);
      setValueAU(event.target.value);
    } else {
      setClick2(false);
      setValueAU(event.target.value);
    }
  };
  const handleChange1 = (event) => {
    setDrop1(event.target.value);
    setClick(true);

    if (
      currentPageUrl === "http://localhost:3000/Flipkart-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Flipkart-add-data"
    ) {
      const filt = flipkartCommission.filter((element) => {
        return element.Vendor_Name === event.target.value;
      });
      setDrop2(filt);
      setBrandF(filt);
    }
    if (
      currentPageUrl === "http://localhost:3000/Myntra-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Myntra-add-data" ||
      currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data" ||
      currentPageUrl === "https://brandfortunes.in/MyntraSJIT-add-data"
    ) {
      const filt = myntraCommission.filter((element) => {
        return element.Vendor_Name === event.target.value;
      });
      setDrop2(filt);
      setBrandF(filt);
    }
    if (
      currentPageUrl === "http://localhost:3000/Amazon-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Amazon-add-data"
    ) {
      const filt = amazonCommission.filter((element) => {
        return element.Vendor_Name === event.target.value;
      });
      setDrop2(filt);
      setBrandF(filt);
    }
  };
  const handleChange2 = (event) => {
    setDrop3(event.target.value);
    setClick(false);
    if (
      currentPageUrl === "http://localhost:3000/Flipkart-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Flipkart-add-data"
    ) {
      const filt = flipkartCommission.filter((element) => {
        return (
          element.Vendor_Name === drop1 &&
          element.Brand_Name === event.target.value
        );
      });
      setDrop4(filt);
    }
    if (
      currentPageUrl === "http://localhost:3000/Myntra-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Myntra-add-data" ||
      currentPageUrl === "https://brandfortunes.in/MyntraSJIT-add-data" ||
      currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data"
    ) {
      const filt = myntraCommission.filter((element) => {
        return (
          element.Vendor_Name === drop1 &&
          element.Brand_Name === event.target.value
        );
      });
      setDrop4(filt);
    }
    if (
      currentPageUrl === "http://localhost:3000/Amazon-add-data" ||
      currentPageUrl === "https://brandfortunes.in/Amazon-add-data"
    ) {
      const filt = amazonCommission.filter((element) => {
        return (
          element.Vendor_Name === drop1 &&
          element.Brand_Name === event.target.value
        );
      });
      setDrop4(filt);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    console.log(file);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData);
      setFile(parsedData);
    };
  };

  async function handleUpload() {
    if (valueAU === "add") {
      try {
        const response = await axios.post("/api/upload", {
          data: file,
          ref: selectedValue,
        });
        console.log(selectedValue);
        console.log(response.data.sqlMessage) || console.log(response);
        setCommand(response);
      } catch (error) {
        // console.error(error.response.data.sqlMessage);
        console.error(error);
        setError(error);
      }
    } else if (valueAU === "update") {
      try {
        const response = await axios.post("/api/upload1", {
          data: file,
          ref: selectedValue,
        });
        console.log(file);
        console.log(selectedValue);
        console.log(response);
        setCommand(response);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
  }

  async function handleUpload1() {
    try {
      const response = await axios.post("/upload1", {
        data: file,
        ref: selectedValue,
      });
      console.log(file);
      console.log(selectedValue);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div class="container1">
      <div class="card">
        <h3>Upload Files</h3>
        <div className="containerforselect">
          <label for="pet-select" className="labelfordropdown">
            Choose:
          </label>
          <select
            name="pets"
            id="pet-select"
            value={valueAU}
            onChange={handleChange}
            style={{ "margin-right": "10px" }}
          >
            <option value="">----ADD/UPDATE----</option>
            <option value="add">Add</option>
            <option value="update">Update</option>
            {/* <option value="flipkart_fixedfees">Flipkart fixed fees</option>
          <option value="flipkart_reverseshippingfees">
            Flipkart reverse shipping fees
          </option>
          <option value="flipkart_shippingfees">Flipkart shipping fees</option>
          <option value="myntra_com">Myntra commission details</option>
          <option value="myntra_forward">Myntra forward fees</option>
          <option value="myntra_reversefees">Myntra reverse fees</option>
          <option value="testing_table">testing_table</option> */}
          </select>

          <label for="pet-select" className="labelfordropdown">
            Choose:
          </label>
          <select
            name="pets"
            id="pet-select"
            value={drop1}
            onChange={handleChange1}
            style={{ "margin-right": "10px" }}
          >
            <option value="">--Vendor Name--</option>
            {currentPageUrl === "http://localhost:3000/Myntra-add-data" ||
            currentPageUrl === "https://brandfortunes.in/Myntra-add-data" ||
            currentPageUrl === "https://brandfortunes.in/MyntraSJIT-add-data" ||
            currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data"
              ? MuniqueVendorNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : currentPageUrl === "http://localhost:3000/Flipkart-add-data" ||
                currentPageUrl === "https://brandfortunes.in/Flipkart-add-data"
              ? uniqueVendorNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : currentPageUrl === "http://localhost:3000/Amazon-add-data" ||
                currentPageUrl === "https://brandfortunes.in/Amazon-add-data"
              ? AuniqueVendorNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : null}

            {/* <option value="flipkart_fixedfees">Flipkart fixed fees</option>
          <option value="flipkart_reverseshippingfees">
            Flipkart reverse shipping fees
          </option>
          <option value="flipkart_shippingfees">Flipkart shipping fees</option>
          <option value="myntra_com">Myntra commission details</option>
          <option value="myntra_forward">Myntra forward fees</option>
          <option value="myntra_reversefees">Myntra reverse fees</option>
          <option value="testing_table">testing_table</option> */}
          </select>
          <label for="pet-select" className="labelfordropdown">
            Choose:
          </label>
          <select
            name="pets"
            id="pet-select"
            value={drop3}
            onChange={handleChange2}
            style={{ "margin-right": "10px" }}
          >
            <option value="">--Brand Name--</option>

            {currentPageUrl === "http://localhost:3000/Flipkart-add-data" ||
            currentPageUrl === "https://brandfortunes.in/Flipkart-add-data"
              ? uniqueBrandNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : currentPageUrl === "http://localhost:3000/Myntra-add-data" ||
                currentPageUrl === "https://brandfortunes.in/Myntra-add-data" ||
                currentPageUrl ===
                  "https://brandfortunes.in/MyntraSJIT-add-data" ||
                currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data"
              ? MuniqueBrandNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : currentPageUrl === "http://localhost:3000/Amazon-add-data" ||
                currentPageUrl === "https://brandfortunes.in/Amazon-add-data"
              ? AuniqueBrandNames.map((val, index) => {
                  return (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  );
                })
              : null}

            {/* <option value="flipkart_fixedfees">Flipkart fixed fees</option>
          <option value="flipkart_reverseshippingfees">
            Flipkart reverse shipping fees
          </option>
          <option value="flipkart_shippingfees">Flipkart shipping fees</option>
          <option value="myntra_com">Myntra commission details</option>
          <option value="myntra_forward">Myntra forward fees</option>
          <option value="myntra_reversefees">Myntra reverse fees</option>
          <option value="testing_table">testing_table</option> */}
          </select>

          {/* <label for="pet-select" className="labelfordropdown">
            Template:
          </label>
          <button className="new" onClick={downloadTemplate}>
            <span className="news">Download</span>
          </button> */}
        </div>

        <label for="pet-select">Choose:</label>

        <select
          name="pets"
          id="pet-select"
          // value={selectedValue}
          onChange={selectTable}
        >
          <option value="">--Please choose table--</option>
          {currentPageUrl === "http://localhost:3000/Flipkart-add-data" ||
          currentPageUrl === "https://brandfortunes.in/Flipkart-add-data" ? (
            <>
              {" "}
              <option value="brandfortunes.Flipkart2_commission">
                Flipkart commision fees
              </option>
              <option value="brandfortunes.Flipkart2_fixedFees">
                Flipkart fixed fees
              </option>
              <option value="brandfortunes.Flipkart2_ReverseShippingFees">
                Flipkart Reverse Shipping fees
              </option>
              <option value="brandfortunes.Flipkart2_ShippingFees">
                Flipkart Shipping fees
              </option>
            </>
          ) : currentPageUrl === "http://localhost:3000/Myntra-add-data" ||
            currentPageUrl === "https://brandfortunes.in/Myntra-add-data" ? (
            <>
              <option value="brandfortunes.Myntra2_Commission">
                Myntra Commission fees
              </option>
              <option value="brandfortunes.Myntra2_Fixedfees">
                Myntra Fixed fees
              </option>
              <option value="brandfortunes.Myntra2_ForwardShippingCharges">
                Myntra Forward Shipping fees
              </option>
              <option value="brandfortunes.Myntra2_ReverseShippingCharges">
                Myntra Reverse Shipping fees
              </option>
              <option value="brandfortunes.new_table">Test</option>
            </>
          ) : currentPageUrl === "http://localhost:3000/Amazon-add-data" ||
            currentPageUrl === "https://brandfortunes.in/Amazon-add-data" ? (
            <>
              <option value="brandfortunes.Amazon_referal_fees">
                Amazon referal fees
              </option>
              <option value="brandfortunes.Amazon_closing_fees">
                Amazon closing fees
              </option>
              <option value="brandfortunes.Amazon_shipping_fees">
                Amazon shipping fees
              </option>

              <option value="brandfortunes.new_table">Test</option>
            </>
          ) : currentPageUrl === "http://localhost:3000/MyntraSJIT-add-data" ||
            currentPageUrl ===
              "https://brandfortunes.in/MyntraSJIT-add-data" ? (
            <>
              <option value="brandfortunes.MyntraSJIT_fixedfees">
                Myntra SJIT fixed fees
              </option>
              <option value="brandfortunes.MyntraSJIT_forward_reverse_fee">
                Myntra SJIT forward fees
              </option>
              <option value="brandfortunes.MyntraSJIT_forward_reverse_fee">
                Myntra SJIT reverse fees
              </option>
              <option value="brandfortunes.myntraSJIT_pick_and_pack_fee">
                Myntra SJIT pick and pack fees
              </option>

              <option value="brandfortunes.new_table">Test</option>
            </>
          ) : null}
          {/* <option value="flipkart_fixedfees">Flipkart fixed fees</option>
          <option value="flipkart_reverseshippingfees">
            Flipkart reverse shipping fees
          </option>
          <option value="flipkart_shippingfees">Flipkart shipping fees</option>
          <option value="myntra_com">Myntra commission details</option>
          <option value="myntra_forward">Myntra forward fees</option>
          <option value="myntra_reversefees">Myntra reverse fees</option>
          <option value="testing_table">testing_table</option> */}
        </select>
        <div className="buttons-2">
          {" "}
          {/* <button className="new">
            <span className="news">Download</span>
          </button> */}
          <button className="new" onClick={downloadTemplate}>
            <span className="news">Template</span>
          </button>
        </div>
        {/* <img src="../../images/download.png"/> */}

        {/* <p>Selected value: {selectedValue}</p> */}
        <div class="drop_box">
          <header>
            <h4>Select File here</h4>
          </header>

          <p>Files Supported: PDF, TEXT, DOC , DOCX</p>
          {/* <input
            type="file"
            // hidden
            accept=".doc,.docx,.pdf"
            id="fileID"
            // style={{ display: "none" }}
          /> */}

          <input
            type="file"
            accept=".xlsx"
            id="fileID"
            onChange={handleFileChange}
          />
          <p className="p1">
            <span>Selected file :</span> {fileName}
          </p>
        </div>
        <div className="buttons-1">
          <button class="btn" onClick={handleUpload}>
            Submit
          </button>
          {/* <button class="btn" onClick={handleUpload1}>
            Update File
          </button> */}
        </div>
        {Object.entries(command).length > 0 ? (
          <div className="command">
            <p className="commandP-tag">
              {command.data[0].message !== "Error"
                ? ` Note : ${command.data[0].message}`
                : `Note : ${command.data[0].error.sqlMessage}`}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminPage;
