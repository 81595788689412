import React from "react";
import axios from "axios";
import "../calculator/Flipkart.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import template from "../template/Template_flipkart_calculator.json";
import { useState, useRef, useEffect } from "react";
import ExcelJS from "exceljs";
const Flipkart = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    dataD: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfees, setFixedfees] = useState([]);
  const [index, setIndex] = useState();
  const [filter1, setFilter1] = useState([]);
  const [filter2, setFilter2] = useState([]);
  const [filter3, setFilter3] = useState([]);
  const [filter4, setFilter4] = useState([]);
  const [data2, setData2] = useState([]);
  const [error, setError] = useState("");
  const [errorContent, setErrorContent] = useState([]);

  useEffect(() => {
    console.log(data2);
  }, [data2]);

  // useEffect(() => {
  //   // First, make sure to include Axios in your project
  //   // You can install it via npm:

  //   // npm install axio

  //   axios
  //     .get("https://jsonplaceholder.typicode.com/posts/1")
  //     .then((response) => {
  //       console.log(response.data); // This will log the data received from the server
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);
  // const bodyRef = useRef(document.body);
  // useEffect(() => {
  //   bodyRef.current.className = "myntra-forward";
  //   return () => {
  //     bodyRef.current.className = bodyRef.current.className
  //       .replace("main-body", "")
  //       .replace("my-body-class", "")
  //       .replace("Amazon-asp", "")
  //       .replace("Myntra-asp", "")
  //       .replace("Flipkart", "");
  //   };
  // }, []);

  // useEffect(() => {
  //   document.body.classList.add("myntra-forward");
  //   return () => {
  //     document.body.classList.remove("main-body");
  //     document.body.classList.remove("my-body-class");
  //     document.body.classList.remove("Flipkart");
  //     document.body.classList.remove("Myntra-asp");
  //     document.body.classList.remove("Amazon-asp");
  //   };
  // }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Fcommission2");
        // console.log("Response:", response);
        setCommissionM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);

  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Ffixedfees2");
        // console.log("Response:", response);
        setFixedfees(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);

  // console.log(reversefeesM);
  // console.log("customer return",formData.data2);
  // console.log("g", formData.dataG);
  // console.log("C", formData.dataC);

  useEffect(() => {
    async function fetchData1() {
      try {
        const response = await axios.get("/api/mynfor");
        // console.log("Response:", response);
        setForwardfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData1();
  }, []);
  useEffect(() => {
    async function fetchData2() {
      try {
        const response = await axios.get("/api/reverseFlipkart");
        // console.log("Response:", response);
        setReversefeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData2();
  }, []);
  console.log(commissionM);
  // if (forwardfeesM.length > 0) {
  //   console.log(forwardfeesM[0]["__EMPTY_2"]);
  // }

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (data2.length > 0) {
      console.log(data2);

      // for (let i = 0; i < commissionM.length; i++) {
      for (let i = 0; i < data2.length; i++) {
        for (let j = 0; j < commissionM.length; j++) {
          if (
            data2[i]["Vendor_Name"].toLowerCase() ===
              commissionM[j]["Vendor_Name"].toLowerCase() &&
            data2[i]["Brand_Name"].toLowerCase() ===
              commissionM[j]["Brand_Name"].toLowerCase() &&
            data2[i]["Gender"].toLowerCase() ===
              commissionM[j]["Gender"].toLowerCase() &&
            data2[i]["Sub_category"].toLowerCase() ===
              commissionM[j]["Sub_category"].toLowerCase()
          ) {
            console.log(j);
            setIndex(j);
            const p1 = commisionfind(j, data2, i);
            console.log("commisionfind", p1);
            const p3 = commissionValue(p1, i);
            console.log("commissionValue", p3);
            const p4 = fixedFeesfind(j);
            console.log("fixedFeesfind", p4);

            const p6 = fff(j, i);
            console.log("forwardfees", p6);
            const p7 = reversechargesGett(j, i);
            console.log("reversechargesGett", p7);
            const p8 = taxget(p3, p4, p6, p7);
            console.log("taxget", p8);
            const p9 = paymentReceipt(p3, p4, p6, p7, p8, i);
            console.log("paymentReceipt", p9);
            setPv(p9);
            addPaymentReceipt(p9, i);
            // setError("Match")
            console.log("Match");
            break;
          } else {
            // setError("Not Match");
            addPaymentReceipterror("Not match", j, i);

            //console.log("Not Match",i)
          }
        }
      }
    }
  };
  console.log(errorContent);

  const addPaymentReceipt = (receipt, index) => {
    setData2((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], ["Payment Receipt"]: receipt };
      return newData;
    });
  };
  const addPaymentReceipterror = (receipt, index, i) => {
    // console.log(index);
    if (index === commissionM.length - 1) {
      setErrorContent((preview) => [...preview, data2[i]]);
      setData2((prevData) => {
        const newData = [...prevData];
        newData[i] = { ...newData[i], ["Payment Receipt"]: receipt };
        return newData;
      });
    }
  };

  const commisionfind = (i, data2, index) => {
    return data2[index]["Asp"] <= 300
      ? commissionM[i]["0-300"]
      : data2[index]["Asp"] > 300 && data2[index]["Asp"] <= 500
      ? commissionM[i]["300-500"]
      : data2[index]["Asp"] > 500 && data2[index]["Asp"] <= 1000
      ? commissionM[i]["500-1000"]
      : data2[index]["Asp"] > 1000
      ? commissionM[i][">1000"]
      : null;
    // console.log("commision",commissionPercentage)
    // setFormData({...formData, commisionF:commissionPercentage})
  };
  const commissionValue = (c, i) => {
    return data2[i]["Asp"] * c;
    // console.log("commissionValue",commissionValue)
    // setFormData({...formData, commissionV:commissionValue})
  };
  const fixedFeesfind = (i) => {
    // console.log(fixedfees[i]["Rate"])
    return fixedfees[i]["Rate"];

    //   console.log("fixedFees",fixedFees)
    //  setFormData({...formData,fixedfeesF:fixedFees})
  };
  const paymentFeesfind = () => {
    return (data2[0]["Asp"] * 0.02) / (1 - formData.data2 / 100);
    // console.log("paymentFees",paymentFees)
    // setFormData({...formData, paymentFeesF:paymentFees})
  };
  const forwardGet = () => {
    return forwardd();
  };
  const fff = (i, index) => {
    const s = forwardfeesM[i + 1]["National_0-0.5Kg"];

    return (s / (100 - data2[index]["Customer Returns"])) * 100;
  };
  const reversechargesGett = (i, index) => {
    console.log(reversefeesM);
    const v = reversefeesM[i + 1]["National_0-0.5Kg"];
    console.log(v, data2[index]["Customer Returns"]);
    // const reversechargesget = vbnhtre();
    // console.log("reversechargesget", reversechargesget);
    return (
      (v * data2[0]["Customer Returns"]) / (100 - data2[0]["Customer Returns"])
    );
  };

  const taxget = (p3, p4, p6, p7) => {
    return (p3 + p4 + p6 + p7) * 0.18;
  };
  const paymentReceipt = (p3, p4, p6, p7, p8, index) => {
    return data2[index]["Asp"] - (p3 + p4 + p6 + p7 + p8);
  };

  function forwardd() {
    if (forwardfeesM.length > 0) {
      if (level[0] === "1") {
        // return forwardfeesM[0]["__EMPTY_2"]
        return forwardfeesM[0]["__EMPTY_2"];
      }
      if (level[0] === "2") {
        return forwardfeesM[1]["__EMPTY_2"];
      }
      if (level[0] === "3") {
        return forwardfeesM[2]["__EMPTY_2"];
      }
      if (level[0] === "4") {
        return forwardfeesM[3]["__EMPTY_2"];
      }
    }
  }
  const handleClick = (e) => {
    setIsDisabled(true);
    mrp.push(e.target.value);
  };
  // function adding(e) {
  //   discount.push(e.target.value);
  // }
  function aspAdd() {
    if (isDisabled) {
      console.log(formData.data0);
      console.log(formData.data1);
      console.log(formData);
      return (formData.data0 * (100 - formData.data1)) / 100;
      // console.log(formData.data0 * (100 - formData.data1) / 100)
    }
  }
  function vbnhtre() {
    if (level[0] === "1") {
      // return forwardfeesM[0]["__EMPTY_2"]
      return reversefeesM[0]["__EMPTY_2"];
    }
    if (level[0] === "2") {
      return reversefeesM[1]["__EMPTY_2"];
    }
    if (level[0] === "3") {
      return reversefeesM[2]["__EMPTY_2"];
    }
    if (level[0] === "4") {
      return reversefeesM[3]["__EMPTY_2"];
    }
  }
  function abc() {
    console.log("yesss");
    const abcd = (formData.data0 * formData.data1) / 100;
    return formData.data0 - abcd;
  }

  const handleFileUpload2 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2(parsedData);
      // };
    };
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data2);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Result-file.xlsx");
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Flipkart-Template-file.xlsx");
  // };
  ////////////////////////////////////////////////////////////////////
  // const template96 = [
  //   { name: "John", age: 28 },
  //   { name: "Jane", age: 22 },
  // ];
  // const downloadTemplate = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("StyledSheet");

  //   // Add headers with styling
  //   worksheet.addRow(["Vendor_Name", "Brand_Name","Gender","Sub_category","Asp","Customer Returns","Payment Receipt"]).font = { bold: true, color: { argb: "FF0000" } };
  //   worksheet.getCell('A1').fill = { fillType: 'solid', fgColor: { argb: 'FFFF00' } }; // Yellow fill

  //   // Add data
  //   template.forEach(data => {
  //     worksheet.addRow([data.Vendor_Name, data.Brand_Name,data.Gender,data.Sub_category,data.Asp,data["Customer Returns"],data["Payment Receipt"]]);
  //   });

  //   // Save the workbook
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], { type: 'application/octet-stream' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = "styled_data.xlsx";
  //   link.click();
  // };
  /////////////////////////////////////////////////////////////////////////////

  const downloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const worksheet2 = workbook.addWorksheet("Sheet 2");

    worksheet.addRow([
      "Vendor_Name",
      "Brand_Name",
      "Gender",
      "Sub_category",
      "Asp",
      "Customer Returns",
      "Payment Receipt",
    ]);
    worksheet.addRow([
      "Varnalaya Clothing",
      "Alaya",
      "Men",
      "ethnic_set",
      "487",
      "20",
      "0",
    ]);
    // worksheet.addRow(['Jane Doe', 25, '']);

    worksheet2.addRow(["Brand Fortunes", "ADOREABOO", "Baby boy.", "backpack"]);
    worksheet2.addRow(["Dhanvi Intimates", "Alaya", "Baby girls", "bag"]);
    worksheet2.addRow([
      "Krisshiv impex",
      "American bull",
      "Boys",
      "bath_towel",
    ]);
    worksheet2.addRow([
      "KULTPRIT Marketing Commerce Pvt Ltd",
      "Chennis",
      "Girls",
      "belt",
    ]);
    worksheet2.addRow([
      "Little Threads",
      "CRAFTING CLUB",
      "Kids-boys",
      "boxer",
    ]);
    worksheet2.addRow([
      "Network Clothing Company Pvt Ltd",
      "Cross Court",
      "Men",
      "bra",
    ]);
    worksheet2.addRow([
      "PERSPECTIVE INTERNATIONAL",
      "Enviously Young",
      "Unisex",
      "brief",
    ]);
    worksheet2.addRow([
      "RISHIKA FASHIONS PVT LTD",
      "Impackt",
      "Women",
      "briefcase",
    ]);
    worksheet2.addRow(["RUG WOODS LLP", "Klephant", "", "Camisole slip"]);
    worksheet2.addRow([
      "Shell Apparels Pvt Ltd",
      "Kryptic",
      "",
      "camisole_slip",
    ]);
    worksheet2.addRow([
      "Sivaraj Spinning Mills (p) Ltd",
      "Kultprit",
      "",
      "capri",
    ]);
    worksheet2.addRow(["Tailor And Circus pvt Ltd", "Missiva", "", "cargo"]);
    worksheet2.addRow([
      "Atom Clothing( Sathiyam Textiles)",
      "Pivot",
      "",
      "churidar",
    ]);
    worksheet2.addRow(["Vennila Clothing Company", "Ramraj", "", "dhoti"]);
    worksheet2.addRow(["SRI CHENNIAANDAVAR TEXTILES", "Rishika", "", "dress"]);
    worksheet2.addRow([
      "Varnalaya Clothing",
      "Rug Woods",
      "",
      "dungaree_romper",
    ]);
    worksheet2.addRow(["", "Seybil", "", "dupatta"]);
    worksheet2.addRow(["", "Shy N Me", "", "ethnic_set"]);
    worksheet2.addRow(["", "Steenbok", "", "fabric"]);
    worksheet2.addRow(["", "Tailor And Circus", "", "jacket"]);
    worksheet2.addRow(["", "The Hollander", "", "jean"]);
    worksheet2.addRow(["", "THE SOUL PATROL", "", "jegging"]);
    worksheet2.addRow(["", "Tom Lang London", "", "jumpsuit"]);
    worksheet2.addRow(["", "Twin birds", "", "kaftan"]);
    worksheet2.addRow(["", "WHITE HEART", "", "kids_apparel_combo"]);
    worksheet2.addRow(["", "", "", "kids_brief"]);
    worksheet2.addRow(["", "", "", "kids_camisole_slip"]);
    worksheet2.addRow(["", "", "", "kids_capri"]);
    worksheet2.addRow(["", "", "", "kids_dress"]);
    worksheet2.addRow(["", "", "", "kids_ethnic_set"]);
    worksheet2.addRow(["", "", "", "kids_jegging"]);
    worksheet2.addRow(["", "", "", "kids_legging"]);
    worksheet2.addRow(["", "", "", "kids_nightwear"]);
    worksheet2.addRow(["", "", "", "kids_panty"]);
    worksheet2.addRow(["", "", "", "kids_short"]);
    worksheet2.addRow(["", "", "", "kids_t_shirt"]);
    worksheet2.addRow(["", "", "", "kids_track_pant"]);
    worksheet2.addRow(["", "", "", "kids_vest"]);
    worksheet2.addRow(["", "", "", "kurta"]);
    worksheet2.addRow(["", "", "", "legging"]);
    worksheet2.addRow(["", "", "", "night_dress_nighty"]);
    worksheet2.addRow(["", "", "", "night_suit"]);
    worksheet2.addRow(["", "", "", "panty"]);
    worksheet2.addRow(["", "", "", "patiala"]);
    worksheet2.addRow(["", "", "", "petticoat"]);
    worksheet2.addRow(["", "", "", "pyjama"]);
    worksheet2.addRow(["", "", "", "sari"]);
    worksheet2.addRow(["", "", "", "shapewear"]);
    worksheet2.addRow(["", "", "", "shirt"]);
    worksheet2.addRow(["", "", "", "shopsy_kids_capri"]);
    worksheet2.addRow(["", "", "", "shopsy_t_shirt"]);
    worksheet2.addRow(["", "", "", "short"]);
    worksheet2.addRow(["", "", "", "shrug"]);
    worksheet2.addRow(["", "", "", "skirt"]);
    worksheet2.addRow(["", "", "", "sling_bag"]);
    worksheet2.addRow(["", "", "", "sweater"]);
    worksheet2.addRow(["", "", "", "sweatshirt"]);
    worksheet2.addRow(["", "", "", "t_shirt"]);
    worksheet2.addRow(["", "", "", "tight"]);
    worksheet2.addRow(["", "", "", "top"]);
    worksheet2.addRow(["", "", "", "track_pant"]);
    worksheet2.addRow(["", "", "", "trouser"]);
    worksheet2.addRow(["", "", "", "vest"]);
    worksheet2.addRow(["", "", "", "waistcoat"]);
    worksheet2.addRow(["", "", "", "wallet_card_wallet"]);

    // const cell1 = worksheet.getCell('A1');
    // cell1.dataValidation = {
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: ["='Sheet 2'!$A$1:$A$16"]
    // }

    // const cell2 = worksheet.getCell('C3');
    // cell2.dataValidation = {
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: ['=A4:A11']
    // };
    for (let i = 2; i <= 250; i++) {
      const cell = worksheet.getCell(`A${i}`);
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$A$1:$A$16"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell2 = worksheet.getCell(`B${i}`);
      cell2.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$B$1:$B$25"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell3 = worksheet.getCell(`C${i}`);
      cell3.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$C$1:$C$8"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell4 = worksheet.getCell(`D${i}`);
      cell4.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$D$1:$D$65"],
      };
    }
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template-file.xlsx");
      })
      .catch((err) => console.error("Error writing Excel file:", err));
  };

  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Flipkart</b>
        </h3>
      </div>

      <section class="u-section-container">
        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload2(e); // Call the handleFileUpload2 function here
            }}
          />
          <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div>
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  {/* Number of Reports for August 2024 :{" "} */}
                  Payment Receipt Finder :
                </h3>
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                            {/* <th
                              rowspan="1"
                              colspan="1"
                              class=""
                              style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              Documents
                            </th> */}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}

                        {/* <td
                              class=""
                              style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              <button
                                tabindex="0"
                                type="button"
                                class="u-button-container regular u-button-text"
                                role="button"
                                data-test-id="target"
                               style={{"text-transform": "uppercase"}}
                              >
                                Download
                              </button>
                            </td> */}

                        {/* <tr>
                            <td
                              class=""
                              style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              Order Flow
                            </td>
                            <td
                              class=""
                              style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              <button
                                tabindex="0"
                                type="button"
                                class="u-button-container regular u-button-text"
                                role="button"
                                data-test-id="target"
                               style={{"text-transform": "uppercase"}}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class=""
                              style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              OTHERS INVOICE
                            </td>
                            <td
                              class=""
                              style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                            >
                              <button
                                tabindex="0"
                                type="button"
                                class="u-button-container regular u-button-text"
                                role="button"
                                data-test-id="target"
                                style={{"text-transform": "uppercase"}}
                              >
                                Download
                              </button>
                            </td>
                          </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flipkart;
