import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntra_ppmp = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [rebate_discount, setRebate_discount] = useState([]);
  const [inputfie, setInputfie] = useState([]);
  const [output, setOutput] = useState([]);
  const [asp, setAsp] = useState();
  const [asp2, setAsp2] = useState();
  const [rebate, setRebate] = useState([]);
  const [answer, setAnswer] = useState();
  const [answer2, setAnswer2] = useState();
  const [registry, setRegistry] = useState([]);
  const [customerReturn, setCustomerReturn] = useState([]);
  const [one, setOne] = useState({});

  // console.log(rebate_discount);

  // console.log(forwardfeesM)
  // const bodyRef = useRef(document.body);
  // useEffect(() => {
  //   bodyRef.current.className = "myntra-forward";
  //   return () => {
  //     bodyRef.current.className = bodyRef.current.className
  //       .replace("main-body", "")
  //       .replace("my-body-class", "")
  //       .replace("Amazon-asp", "")
  //       .replace("Myntra-asp", "")
  //       .replace("Flipkart", "");
  //   };
  // }, []);

  // useEffect(() => {
  //   document.body.classList.add("myntra-forward");
  //   return () => {
  //     document.body.classList.remove("main-body");
  //     document.body.classList.remove("my-body-class");
  //     document.body.classList.remove("Flipkart");
  //     document.body.classList.remove("Myntra-asp");
  //     document.body.classList.remove("Amazon-asp");
  //   };
  // }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/MyntraRegistries");
        // console.log("Response:", response);
        setRegistry(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/MCommission2");
        // console.log("Response:", response);
        setCommissionM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Mfixedfees2");
        // console.log("Response:", response);
        setFixedfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Mlevel2");
        // console.log("Response:", response);
        setLevelS(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData1() {
      try {
        const response = await axios.get("/api/Mforwardfees2");
        // console.log("Response:", response);
        setForwardfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData1();
  }, []);
  useEffect(() => {
    async function fetchData2() {
      try {
        const response = await axios.get("/api/Mreversefees2");
        // console.log("Response:", response);
        setReversefeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData2();
  }, []);

  // console.log(commissionM);
  // console.log(fixedfeesM);
  // console.log(levelS);

  // console.log(reversefeesM);
  // if (forwardfeesM.length > 0) {
  //   console.log(forwardfeesM[0]["__EMPTY_2"]);
  // }

  const level = [];
  const mrp = [];
  const discount = [];
  let output_ = [];
  const customerReturn_array = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log(commissionM.length)
    if (commissionM.length > 0 && levelS.length > 0) {
      for (let i = 0; i < data2.length; i++) {
        const D = discountfind(data2[i]);
        console.log(D);
        const { discount0, MRP } = D;
        const Discount_ = discount0 / 100;
        const asp_ = MRP * (1 - Discount_);

        console.log(asp_);
        // setAsp(asp_);

        for (let j = 0; j < commissionM.length; j++) {
          // console.log(formData.dataC, commissionM[0]["Brand"]);
          const Brandname = D.Brand.toLowerCase();
          const Brand = commissionM[j]["Brand_Name"].toLowerCase();
          const Gender = D.gender.toLowerCase();
          const Gen = commissionM[j]["Gender"].toLowerCase();
          const Category = D.Category.toLowerCase();
          const Cat = commissionM[j]["Sub_category"].toLowerCase();


          if (Brandname === Brand && Gender === Gen && Category === Cat) {
            console.log("yessssssssssssssss");
            // console.log(commissionM[j]["Vendor_Name"]);
            const shippingLevel = commissionM[j]["Article_level"];

            console.log("shippingLevel", shippingLevel);
            level.push(shippingLevel);
            console.log("storage", level);
            console.log("j", j);
            const p1 = commisionfind(j);
            console.log("commisionfind", p1);
            const p3 = commissionValue(p1, i, asp_);
            console.log("commissionValue", p3);
            const p4 = fixedFeesfind(j, i, asp_, D);
            console.log("fixedFeesfind", p4);
            const p5 = paymentFeesfind(i, asp_, D);
            console.log("paymentFeesfind", p5);
            const p2 = forwardGet(j, i);
            console.log("forwardGet", p2);
            const p6 = fff(p2, i);
            console.log("forwardfees", p6);
            const p7 = reversechargesGett(i);
            console.log("reversechargesGett", p7);
            const p8 = taxget(p3, p4, p5, p6, p7);
            console.log("taxget", p8);
            const p9 = paymentReceipt(p3, p4, p5, p6, p7, p8, i, asp_);
            console.log("paymentReceipt", p9);
            const realization = Re(p9, asp_);
            console.log("realization", realization);

            setPv(p9);
            setAnswer(p9);
            secondCalc(i, p9, j);
            // addPaymentReceipt(p9, i);
            break;
          } else {
            console.log("not match");
             if (commissionM.length -1 === j){
            addOutput(i, MRP, discount0, "NAN", D);
            console.log(j)
          }
            // console.log(Brandname ,Brand,
            //   Gender ,Gen,
            //   Category ,Cat)
            // addPaymentReceipterror("Not match", j, i);
          }
          // if (commissionM.length === j){
          //   addOutput(i, MRP, discount0, "NAN", D);
          // }
        }
      }
    }
  };

  const secondCalc = (i, asp1, j) => {
    if (commissionM.length > 0) {
      // for (let i = 0; i < data2.length; i++) {

      const D = discountfind(data2[i]);
      // const discount2 = data2[i]["Target TD Lower Limit"];
      console.log(D);
      const { discount0, MRP } = D;
      const Discount_ = discount0 / 100;
      const asp_0 = MRP * (1 - Discount_);
      const Coupon = one.b / 100;
      const asp_ = asp_0 * (1 - Coupon);
      console.log(asp_,asp_0);
      // setAsp2(asp_);

      // for (let j = 0; j < commissionM.length; j++) {
      // console.log(formData.dataC, commissionM[0]["Brand"]);
      //  const Brandname =  data2[i]["Brand"].toLowerCase()
      //  const Brand = commissionM[j]["Brand_Name"].toLowerCase()
      //  const Gender = data2[i]["Gender"].toLowerCase()
      //  const Gen = commissionM[j]["Gender"].toLowerCase()
      //  const Category = data2[i]["Article Type"].toLowerCase()
      //  const Cat = commissionM[j]["Sub_category"].toLowerCase()
      // if (
      //   // data2[i]["Vendor_Name"] === commissionM[j]["Vendor_Name"] &&
      //   data2[i]["Brand"]
      // ) {
      // console.log(commissionM[j]["Vendor_Name"]);
      // const shippingLevel =  1;
      // console.log("shippingLevel", shippingLevel);
      // level.push(shippingLevel);
      // console.log("storage", level);
      console.log(j);
      const p1 = commisionfind(j);
      console.log("commisionfind", p1);
      const p3 = commissionValue2(p1, i, asp_);
      console.log("commissionValue", p3);
      const p4 = fixedFeesfind2(j, i, asp_);
      console.log("fixedFeesfind", p4);
      const p5 = paymentFeesfind2(i, asp_);
      console.log("paymentFeesfind", p5);
      const p2 = forwardGet(j, i);
      console.log("forwardGet", p2);
      const p6 = fff(p2, i);
      console.log("forwardfees", p6);
      const p7 = reversechargesGett(i);
      console.log("reversechargesGett", p7);
      const p8 = taxget(p3, p4, p5, p6, p7);
      console.log("taxget", p8);
      // const rebate = findRebate(data2[i], asp_);
      // console.log("rebate", rebate);

      const p9 = paymentReceipt2(p3, p4, p5, p6, p7, p8, i, asp_);
      console.log("paymentReceipt", p9);
      const realization = Re(p9, asp_0);
      console.log("realization", realization);
      const profitLoss = Pro(asp1, p9);
      console.log("profitLoss", profitLoss);
      const Result_ = kk(profitLoss, asp1);
      console.log("result", Result_);
      // setPv(p9);
      setAnswer2(p9);
      addOutput(i, MRP, discount0, Result_, D);

      // addPaymentReceipt(p9, i);
      // } else {
      //   console.log("not match");
      //   // console.log(Brandname ,Brand,
      //   //   Gender ,Gen,
      //   //   Category ,Cat)
      //   // addPaymentReceipterror("Not match", j, i);
      // }

      // }
      // }
    }
  };
  console.log(answer);
  console.log(answer2);
  const discountfind = (val) => {
    const styleID = val["StyleId"];
    console.log(styleID,rebate_discount[0]["STYLE ID"]);
    for (let i = 0; i < rebate_discount.length; i++) {
      if (styleID === rebate_discount[i]["STYLE ID"]) {
        const A = findGender(i);
        console.log(A);
        const B= rebate_discount[i]["DISCOUNT %"] === undefined ? 0 : rebate_discount[i]["DISCOUNT %"]
        return {
          discount0: B,
          MRP: rebate_discount[i]["MRP"],
          Category: rebate_discount[i]["ARTICLE TYPE"],
          Brand: rebate_discount[i]["BRAND NAME"],
          gender: A,
        };
      }
    }
  };

  const findGender = (i) => {
    return rebate_discount[i]["MASTER CATEGORY"] === "BoysApparel"
      ? "Boys"
      : rebate_discount[i]["MASTER CATEGORY"] === "GirlsAccessories"
      ? "Girls"
      : rebate_discount[i]["MASTER CATEGORY"] === "GirlsApparel"
      ? "Girls"
      : rebate_discount[i]["MASTER CATEGORY"] === "MenApparel"
      ? "Men"
      : rebate_discount[i]["MASTER CATEGORY"] === "UnisexApparel"
      ? "Unisex"
      : rebate_discount[i]["MASTER CATEGORY"] === "WomenApparel"
      ? "Women"
      : null;
  };

  const addOutput = (i, MRP, discount0, Result_, D) => {
    const styleID = data2[i]["StyleId"];
    const Brand = D.Brand.toLowerCase();
    const Gender = D.gender.toLowerCase();
    const Articaltype = D.Category.toLowerCase();
    // const rebate = data2[0]["Projected Rebate Per Order"]
    // ? data2[i]["Rebate %"]
    // : data2[i]["Discount"];
    const zz = {
      StyleID: styleID,
      Brand: Brand,
      "Article type": Articaltype,
      Gender: Gender,
      MRP: MRP,
      "Current Discount": discount0,
      "Coupon Code": one.a,
      "Coupon discount": one.b,

      // "Event Discount": discount2,
      // "Rebate Offered": rebate,
      "Profit/Loss": Result_,
    };
    console.log(zz);

    setRebate((prevRebate) => [...prevRebate, zz]);

    // if (i===1){
    //   setRebate(zz)
    // }
  };
  const Re = (a, b) => {
    console.log(a,b)
    return a / b;
  };

  const Pro = (a, b) => {
    console.log(answer, answer2);
    return b - a;
  };
  const kk = (a, b) => {
    console.log(a, b);
    const mm = (a / b) * 100;
    const nn = mm.toFixed(2);
    return nn;
  };

  const findRebate = (val, asp2) => {
    const A = val["Rebate %"];
    console.log(val);
    console.log(A);
    const C = A / 100;
    return C * asp2 * 1.18;
  };
  const addPaymentReceipterror = (receipt, index, i) => {
    // console.log(index);
    if (index === commissionM.length - 1) {
      setErrorContent((preview) => [...preview, data2[i]]);
      setData2((prevData) => {
        const newData = [...prevData];
        newData[i] = { ...newData[i], ["Payment Receipt"]: receipt };
        return newData;
      });
    }
  };
  const addPaymentReceipt = (receipt, index) => {
    setData2((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], ["Payment Receipt"]: receipt };
      return newData;
    });
  };
  const commisionfind = (i) => {
    return commissionM[i]["Commission_"];
    // console.log("commision",commissionPercentage)
    // setFormData({...formData, commisionF:commissionPercentage})
  };
  const commissionValue = (c, i, asp) => {
    if (data2.length > 0) {
      console.log(asp);
      return (asp * c) / 100;
    } else {
      return 0; // or some default value
    }
  };
  const commissionValue2 = (c, i, asp2) => {
    if (data2.length > 0) {
      console.log(asp2);
      return (asp2 * c) / 100;
    } else {
      return 0; // or some default value
    }
  };
  const fixedFeesfind = (indexj, index, asp, D) => {
    for (let i = 0; i < fixedfeesM.length; i++) {
      const Brandname = D.Brand.toLowerCase();
      const Brand = fixedfeesM[i]["Brand_Name"].toLowerCase();
      const Gender = D.gender.toLowerCase();
      const Gen = fixedfeesM[i]["Gender"].toLowerCase();
      const Category = D.Category.toLowerCase();
      const Cat = fixedfeesM[i]["Sub_category"].toLowerCase();

      if (Brandname === Brand && Gender === Gen && Category === Cat) {
        return asp <= 500
          ? fixedfeesM[i + 1]["0-500"]
          : asp > 500 && asp <= 750
          ? fixedfeesM[i + 1]["500-750"]
          : asp > 750 && asp <= 1000
          ? fixedfeesM[i + 1]["750-1000"]
          : asp > 1000 && asp <= 2000
          ? fixedfeesM[i + 1]["1000-2000"]
          : asp > 2000
          ? fixedfeesM[i + 1][">2000"]
          : null;
      } else {
        console.log("fixed fees not match");
      }
    }
    //   console.log("fixedFees",fixedFees)
    //  setFormData({...formData,fixedfeesF:fixedFees})
  };
  const fixedFeesfind2 = (i, index, asp2) => {
    return asp2 <= 500
      ? fixedfeesM[i + 1]["0-500"]
      : asp2 > 500 && asp2 <= 750
      ? fixedfeesM[i + 1]["500-750"]
      : asp2 > 750 && asp2 <= 1000
      ? fixedfeesM[i + 1]["750-1000"]
      : asp2 > 1000 && asp2 <= 2000
      ? fixedfeesM[i + 1]["1000-2000"]
      : asp2 > 2000
      ? fixedfeesM[i + 1][">2000"]
      : null;
    //   console.log("fixedFees",fixedFees)
    //  setFormData({...formData,fixedfeesF:fixedFees})
  };
  const paymentFeesfind = (i, asp, D) => {
    for (let j = 0; j < registry.length; j++) {
      // console.log(formData.dataC, commissionM[0]["Brand"]);
      const Brand = registry[j]["Brand_Name"].toLowerCase();
      const Gen = registry[j]["Gender"].toLowerCase();
      const Cat = registry[j]["Sub_category"].toLowerCase();

      const Brandname = D.Brand.toLowerCase();
      const Gender = D.gender.toLowerCase();
      const Category = D.Category.toLowerCase();

      if (data2[i]["Customer_return"]) {
        const r = data2[i]["Customer_return"];
        return (asp * 0.02) / (1 - r);
      } else {
        if (Brandname === Brand && Gender === Gen && Category === Cat) {
          console.log("customer return", registry[j]["Customer_returns"]);
          setCustomerReturn(registry[j]["Customer_returns"]);
          const t = Number(registry[j]["Customer_returns"]) / 100;
          customerReturn_array.push(t);
          console.log(asp, Number(registry[j]["Customer_returns"]));
          return (asp * 0.02) / (1 - t);
        } else {
          console.log("customer return not match");
        }
      }
    }
    // console.log("paymentFees",paymentFees)
    // setFormData({...formData, paymentFeesF:paymentFees})
  };

  const paymentFeesfind2 = (i, asp2) => {
    console.log(customerReturn_array);

    return (
      (asp2 * 0.02) /
      (1 - customerReturn_array[customerReturn_array.length - 1])
    );
    // console.log("paymentFees",paymentFees)
    // setFormData({...formData, paymentFeesF:paymentFees})
  };
  const forwardGet = (i, index) => {
    return forwardd(i, index);
  };
  const fff = (s, i) => {
    return s / (1 - customerReturn_array[customerReturn_array.length - 1]);
  };
  const reversechargesGett = (i) => {
    const reversechargesget = vbnhtre(i);
    console.log("reversechargesget", reversechargesget);
    return (
      (reversechargesget *
        customerReturn_array[customerReturn_array.length - 1]) /
      (1 - customerReturn_array[customerReturn_array.length - 1])
    );
  };

  const taxget = (p3, p4, p5, p6, p7) => {
    console.log(p3, p4, p5, p6, p7);
    return (p3 + Number(p4) + p5 + p6 + p7) * 0.18;
  };
  const paymentReceipt = (p3, p4, p5, p6, p7, p8, i, asp) => {
    return asp - (p3 + Number(p4) + p5 + p6 + p7 + p8);
  };
  const paymentReceipt2 = (p3, p4, p5, p6, p7, p8, i, asp2) => {
    const A = asp2 - (p3 + Number(p4) + p5 + p6 + p7 + p8);
    return A;
  };

  function forwardd(i, index) {
    if (forwardfeesM.length > 0) {
      console.log(level[index],i,index);
      if (level[index] == 1) {
        console.log("yes arrived 1");
        // return forwardfeesM[0]["__EMPTY_2"]
        return forwardfeesM[1]["PPMP1st_Unit_National"];
      }
      if (level[index] == 2) {
        console.log("yes arrived 2");

        return forwardfeesM[2]["PPMP1st_Unit_National"];
      }
      if (level[index] == 3) {
        console.log("yes arrived 3");

        return forwardfeesM[3]["PPMP1st_Unit_National"];
      }
      if (level[index] == 4) {
        console.log("yes arrived 4");

        return forwardfeesM[4]["PPMP1st_Unit_National"];
      }
      if (level[index] == 5) {
        console.log("yes arrived 5");

        return forwardfeesM[5]["PPMP1st_Unit_National"];
      }

      // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[7]["column_6"];
      // }
      // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[8]["column_6"];
      // }
      // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[9]["column_6"];
      // }
      // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[10]["column_6"];
      // }
      // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[11]["column_6"];
      // }
    }else {
      console.log("no forward fees");
    }
  }
  const handleClick = (e) => {
    setIsDisabled(true);
    mrp.push(e.target.value);
  };
  // function adding(e) {
  //   discount.push(e.target.value);
  // }
  function aspAdd() {
    if (isDisabled) {
      // console.log(formData.data0);
      // console.log(formData.data1);
      // console.log(formData);
      return (formData.data0 * (100 - formData.data1)) / 100;
      // console.log(formData.data0 * (100 - formData.data1) / 100)
    }
  }
  function vbnhtre(i) {
    if (level[i] == 1) {
      // return forwardfeesM[0]["__EMPTY_2"]
      return reversefeesM[1]["PPMP1st_Unit_National"];
    }
    if (level[i] == 2) {
      return reversefeesM[2]["PPMP1st_Unit_National"];
    }
    if (level[i] == 3) {
      return reversefeesM[3]["PPMP1st_Unit_National"];
    }
    if (level[i] == 4) {
      return reversefeesM[4]["PPMP1st_Unit_National"];
    }
    if (level[i] == 5) {
      return reversefeesM[5]["PPMP1st_Unit_National"];
    }

    // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[6]["column_6"];
    // }
    // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[7]["column_6"];
    // }
    // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[8]["column_6"];
    // }
    // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[9]["column_6"];
    // }
    // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[10]["column_6"];
    // }
  }
  function abc() {
    console.log("yesss");
    const abcd = (formData.data0 * formData.data1) / 100;
    return formData.data0 - abcd;
  }
  const handleFileUpload2 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2(parsedData);
      // };
    };
  };
  const handleFileUpload6 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      //   console.log(parsedData)
      setRebate_discount(parsedData);
      // };
    };

    // axios.post('/api/rebatediscount', {
    //     file: rebate_discount

    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rebate);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Result-file.xlsx");
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };
  const downloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const worksheet2 = workbook.addWorksheet("Sheet 2");

    worksheet.addRow([
      "Vendor_Name",
      "Brand_Name",
      "Gender",
      "Sub_category",
      "Asp",
      "Customer Returns",
      "Payment Receipt",
    ]);
    worksheet.addRow([
      "Sathyam Textiles",
      "THE HOLLANDER",
      "Men",
      "Tshirts",
      "487",
      "20",
      "0",
    ]);
    // worksheet.addRow(['Jane Doe', 25, '']);

    const Vendor_Name = [
      "Bedbathandbliss",
      "Brand Fortunes",
      "Dhanalaxmi Fashions",
      "Eclat Globalbiz LLP",
      "GIVO FASHIONS PRIVATE LIMITED",
      "Global Elle",
      "IMPERIUM INC",
      "INDI KRITI",
      "KULTPRIT Marketing Commerce Pvt Ltd",
      "LITTLE THREADS",
      "M/S HOMERZ SOLUTION",
      "Mohanlal Sons",
      "Network Clothing Company Pvt Ltd",
      "Opus Fashions Pvt. Ltd",
      "ORI VENTURES PRIVATE LIMITED",
      "Paras Textiles",
      "RISHIKA FASHIONS PVT LTD",
      "Sathyam Textiles",
      "SHARMA SALES",
      "Shree Rashmi Dresses",
      "SIVARAJ SPINNING MILLS (P) LTD",
      "Tailor and Circus Clothing Pvt Ltd",
      "Varnalaya Clothing",
    ];

    const Brand_Name = [
      "&CIRCUS",
      "Alaya",
      "American Bull",
      "Anouk",
      "Ariel",
      "Bedbathandbliss",
      "Braveo",
      "Chennis",
      "DEYANN",
      "DEYANN PLUS",
      "Enviously Young",
      "Ethniks Neu-Ron",
      "Givo",
      "Homerz",
      "House of Pataudi",
      "IMPACKT",
      "Inkriti",
      "Jansons",
      "Klephant",
      "Kryptic",
      "KULTPRIT",
      "La Trove",
      "LAMAAYA",
      "Little Chieftains",
      "Maybell",
      "MOHANLAL SONS",
      "Nifty",
      "Ode by House of Pataudi",
      "pivot",
      "Polka Tots",
      "Purple State",
      "Rishika",
      "SARSO",
      "SEIYON",
      "SEYBIL",
      "SI2 SLIP IN 2",
      "Steenbok",
      "Sztori",
      "Tailor & Circus",
      "THE HOLLANDER",
      "THE SOUL PATROL",
      "Twin birds",
      "WHITE HEART",
    ];

    const gender = [
      "Boys",
      "Girls",
      "Kids-boys",
      "Men",
      "Unisex",
      "Unisex Kids",
      "Women",
    ];

    const category = [
      "Baby Carriers",
      "Baby Gear & Nursery",
      "Bath Towels",
      "Bath Tub",
      "Bedsheets",
      "Bibs",
      "Blankets Quilts and Dohars",
      "Blazers",
      "Bodysuit",
      "Boxers",
      "Bra",
      "Briefs",
      "Camisoles",
      "Capris",
      "Churidar",
      "Clothing Set",
      "Co-Ords",
      "Dhotis",
      "Diaper Bags",
      "Diapers",
      "Dinner Set",
      "Diwan Set",
      "Dresses",
      "Dungarees",
      "Dupatta",
      "Ethnic Dresses",
      "Feeding Essentials",
      "Hand Towels",
      "Harem Pants",
      "Innerwear Vests",
      "Jackets",
      "Jeans",
      "Jeggings",
      "Jumpsuit",
      "Kitchen Towels",
      "Kurta Sets",
      "Kurtas",
      "Laundry Bag",
      "Leggings",
      "Lehenga Choli",
      "Lounge Pants",
      "Lounge Shorts",
      "Lounge Tshirts",
      "Nehru Jackets",
      "Night suits",
      "Nightdress",
      "Organisers",
      "Palazzos",
      "Patiala",
      "Pillow Covers",
      "Pillows",
      "Rompers",
      "Saree Blouse",
      "Shapewear",
      "Shawl",
      "Sherwani",
      "Shirts",
      "Shorts",
      "Shrug",
      "Skirts",
      "Sweatshirts",
      "Table Covers",
      "Tent",
      "Tights",
      "Tops",
      "Track Pants",
      "Trousers",
      "Trunk",
      "Tshirts",
    ];

    for (let i = 0; i < category.length; i++) {
      worksheet2.addRow([
        Vendor_Name[i] === undefined ? "" : Vendor_Name[i],
        Brand_Name[i] === undefined ? "" : Brand_Name[i],
        gender[i] === undefined ? "" : gender[i],
        category[i],
      ]);
    }

    for (let i = 2; i <= 250; i++) {
      const cell = worksheet.getCell(`A${i}`);
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$A$1:$A$16"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell2 = worksheet.getCell(`B${i}`);
      cell2.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$B$1:$B$25"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell3 = worksheet.getCell(`C${i}`);
      cell3.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$C$1:$C$8"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell4 = worksheet.getCell(`D${i}`);
      cell4.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$D$1:$D$65"],
      };
    }
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template-file.xlsx");
      })
      .catch((err) => console.error("Error writing Excel file:", err));
  };

  console.log(data2);
  // console.log(commissionM);
  // console.log(output);
  console.log(rebate);
  console.log(data2.length, rebate.length);
  const handleSubmit = (e) => {
    e.preventDefault(); // prevent the default form submission behavior
    console.log(e.target.elements.username.value);
    console.log(e.target.elements.confirmUsername.value);
    setOne({
      a: e.target.elements.username.value,
      b: e.target.elements.confirmUsername.value,
    });
  };
  console.log(one);
  console.log(rebate_discount);
  return (
    // <form action="" method="get" className="form-example padding-top">
    //   <div className="gamut-14z39fp-LayoutGrid 14g39">
    //     {formData.xcv && (
    //       <div className="form-example1">
    //         <label className="myntra" for="data0">
    //           Enter MRP:{" "}
    //         </label>
    //         <input
    //           type="text"
    //           name="data0"
    //           id="0011"
    //           onChange={(e) => {
    //             setFormData({
    //               ...formData,
    //               data0: parseInt(e.target.value),
    //               zlc: false,
    //             });
    //             handleClick(e);
    //           }}
    //         />

    //         <label className="myntra" for="data1">
    //           Enter Discount:{" "}
    //         </label>
    //         <input
    //           type="text"
    //           name="data1"
    //           id="0011"
    //           onChange={(e) => {
    //             setFormData({ ...formData, data1: parseInt(e.target.value) });
    //           }}
    //         />

    //         <button
    //           className="form-example23"
    //           onClick={(e) => {
    //             setFormData({ ...formData, ASP: aspAdd(), xcv: false });
    //           }}
    //           role="button"
    //         >
    //           Calc
    //         </button>
    //       </div>
    //     )}
    //     <div className="form-example">
    //       <p>Selling Price: {formData.ASP}</p>
    //       <label className="myntra" for="data3">
    //         Enter ASP:{" "}
    //       </label>
    //       <input
    //         type="text"
    //         name="data3"
    //         id="0011"
    //         value={formData.ASP || 0}
    //         onChange={(e) => {
    //           setFormData({
    //             ...formData,
    //             ASP: parseInt(e.target.value),
    //             xcv: false,
    //           });
    //         }}
    //         disabled={isDisabled}
    //       />
    //     </div>

    //     <div className="form-example">
    //       <label className="myntra" for="data2">
    //         Enter Customer Returns:{" "}
    //       </label>
    //       <input
    //         type="text"
    //         name="data2"
    //         id="0011"
    //         onChange={(e) =>
    //           setFormData({ ...formData, data2: parseInt(e.target.value) })
    //         }
    //         required
    //       />
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataB">
    //         {" "}
    //         Vendor Name:
    //       </label>

    //       <select
    //         name="dataB"
    //         id="dataB"
    //         value={formData.dataB}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataB: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[...new Set(commissionM.map((data) => data["Vendor_Name"]))].map(
    //           (vendorName, index) => (
    //             <option key={index} value={vendorName}>
    //               {vendorName}
    //             </option>
    //           )
    //         )}
    //       </select>
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataC">
    //         Brand Name:
    //       </label>

    //       <select
    //        name="dataC"
    //        id="dataB"
    //        value={formData.dataC}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataC: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[
    //           ...new Set(
    //             commissionM
    //               .filter((data) => data["Vendor_Name"] == formData.dataB)
    //               .map((data) => data["Brand"])
    //           ),
    //         ].map((data, index) => (
    //           <option key={index} value={data}>
    //             {data}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataG">
    //         Gender:
    //       </label>

    //       <select
    //         name="dataG"
    //         id="dataB"
    //         value={formData.dataG}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataG: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[
    //           ...new Set(
    //             commissionM
    //               .filter((data) => data["Brand"] == formData.dataC)
    //               .map((data) => data["Gender"])
    //           )
    //         ].map((data, index) => (
    //           <option key={index} value={data}>
    //             {data}
    //           </option>
    //         ))}
    //       </select>
    //     </div>

    //     <div className="form-example">
    //       <label className="myntra" for="dataD">
    //         Category:
    //       </label>

    //       <select
    //         name="dataD"
    //         id="dataB"
    //         value={formData.dataD}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataD: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {commissionM
    //           .filter(
    //             (data) =>
    //               data["Gender"] == formData.dataG &&
    //               data["Brand"] == formData.dataC
    //           )
    //           .map((data, index) => (
    //             <option key={index} value={data["ArticleType"]}>
    //               {data["ArticleType"]}
    //             </option>
    //           ))}
    //       </select>
    //     </div>

    //     <button
    //       className="form-example23"
    //       onClick={(e) => {
    //         handleFormSubmit(e);
    //       }}
    //       role="button"
    //     >
    //       Calc
    //     </button>

    //     <div className="form-example">
    //       <label className="myntra" for="dataPV">
    //         Payment Receipt:{" "}
    //       </label>
    //       <input
    //         type="number"
    //         name="dataPV"
    //         id="0011"
    //         value={pv}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataPV: e.target.value })
    //         }
    //         required
    //       />
    //     </div>
    //   </div>
    //   <div id="transformers">
    //     <button id="ksi" onClick={() => window.location.reload()}>
    //       <span id="text">Reload</span>
    //     </button>
    //   </div>
    // </form>
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Myntra Opt-in</b>
        </h3>
      </div>
      <section class="u-section-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label for="username">Enter Coupon Code : </label>
            <input type="text" id="username" name="username" />
            <label for="confirmUsername">Enter Coupon Value : </label>
            <input type="text" id="confirmUsername" name="confirmUsername" />
            <button className="abButton" type="submit">
              Submit
            </button>
          </div>
        </form>
      </section>

      <section class="u-section-container">
        <div>
          <label>file 1 :</label>

          <input
            className="input1"
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload2(e); // Call the handleFileUpload2 function here
            }}
          />
          <label>file 2 :</label>
          <input
            className="input1"
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload6(e); // Call the handleFileUpload2 function here
            }}
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  {/* Number of Reports for August 2024 :{" "} */}
                  Payment Receipt Finder :
                </h3>
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  {data2.length === rebate.length ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcel}
                    >
                      Download Result
                    </div>
                  ) : null}
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {/* {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))} */}

                      {/* <th
                            rowspan="1"
                            colspan="1"
                            class=""
                            style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Documents
                          </th> */}
                      {/* </tr>
                        </thead> */}
                      {/* )} */}
                      <tbody>
                        {/* {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))} */}

                        {/* <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                             style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td> */}

                        {/* <tr>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Order Flow
                          </td>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                             style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            OTHERS INVOICE
                          </td>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                              style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntra_ppmp;
