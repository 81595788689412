import React from "react";
import "../../css/Myntra.css";
import "../../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const MyntraforwardSJIT = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [pick, setPick] = useState([]);

  console.log(forwardfeesM);
  // const bodyRef = useRef(document.body);
  // useEffect(() => {
  //   bodyRef.current.className = "myntra-forward";
  //   return () => {
  //     bodyRef.current.className = bodyRef.current.className
  //       .replace("main-body", "")
  //       .replace("my-body-class", "")
  //       .replace("Amazon-asp", "")
  //       .replace("Myntra-asp", "")
  //       .replace("Flipkart", "");
  //   };
  // }, []);

  // useEffect(() => {
  //   document.body.classList.add("myntra-forward");
  //   return () => {
  //     document.body.classList.remove("main-body");
  //     document.body.classList.remove("my-body-class");
  //     document.body.classList.remove("Flipkart");
  //     document.body.classList.remove("Myntra-asp");
  //     document.body.classList.remove("Amazon-asp");
  //   };
  // }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/pickandpackfee");
        // console.log("Response:", response);
        setPick(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/MCommission2");
        // console.log("Response:", response);
        setCommissionM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/MyntraSJITfixedfees");
        // console.log("Response:", response);
        setFixedfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Mlevel2");
        // console.log("Response:", response);
        setLevelS(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData1() {
      try {
        const response = await axios.get("/api/MyntraSJITforward");
        // console.log("Response:", response);
        setForwardfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData1();
  }, []);
  useEffect(() => {
    async function fetchData2() {
      try {
        const response = await axios.get("/api/MyntraSJIT_reverse_fee");
        // console.log("Response:", response);
        setReversefeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData2();
  }, []);

  //console.log(commissionM);
  // console.log(fixedfeesM);
  // console.log(levelS);

  // console.log(reversefeesM);
  // if (forwardfeesM.length > 0) {
  //   console.log(forwardfeesM[0]["__EMPTY_2"]);
  // }

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log(commissionM.length)
    if (commissionM.length > 0 && levelS.length > 0) {
      for (let i = 0; i < data2.length; i++) {
        for (let j = 0; j < commissionM.length; j++) {
          // console.log(formData.dataC, commissionM[0]["Brand"]);
          if (
            data2[i]["Vendor_Name"] === commissionM[j]["Vendor_Name"] &&
            data2[i]["Brand_Name"] === commissionM[j]["Brand_Name"] &&
            data2[i]["Gender"] === commissionM[j]["Gender"] &&
            data2[i]["Sub_category"] === commissionM[j]["Sub_category"]
          ) {
            console.log(commissionM[j]["Vendor_Name"]);
            const shippingLevel = commissionM[j]["Article_level"];
            console.log("shippingLevel", shippingLevel);
            level.push(shippingLevel === undefined ? "undefined" : shippingLevel );
            console.log("storage", level);
            console.log(j);
            const p1 = commisionfind(j);
            console.log("commisionfind", p1);
            const p3 = commissionValue(p1, i);
            console.log("commissionValue", p3);
            const p4 = fixedFeesfind(j, i);
            console.log("fixedFeesfind", p4);
            const p5 = paymentFeesfind(i);
            console.log("paymentFeesfind", p5);
            const p2 = forwardGet(j, i);
            console.log("forwardGet", p2);
            const p6 = fff(p2, i);
            console.log("forwardfees", p6);
            const p7 = reversechargesGett(i);
            console.log("reversechargesGett", p7);
            const pickpack = Pick(i);
            console.log("pick and pack fee", pickpack);
            const p8 = taxget(p3, p4, p5, p6, p7,pickpack);
            console.log("taxget", p8);
            const p9 = paymentReceipt(p3, p4, p5, p6, p7, p8, i,pickpack);
            console.log("paymentReceipt", p9);
            setPv(p9);
            addPaymentReceipt(p9, i);
            break;
          } else {
            console.log("not match");
            addPaymentReceipterror("Not match", j, i);
          }
        }
      }
    }
  };

  const addPaymentReceipterror = (receipt, index, i) => {
    // console.log(index);
    if (index === commissionM.length - 1) {
      setErrorContent((preview) => [...preview, data2[i]]);
      setData2((prevData) => {
        const newData = [...prevData];
        newData[i] = { ...newData[i], ["Payment Receipt"]: receipt };
        return newData;
      });
    }
  };
  const addPaymentReceipt = (receipt, index) => {
    setData2((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], ["Payment Receipt"]: receipt };
      return newData;
    });
  };
  const commisionfind = (i) => {
    return commissionM[i]["Commission_"];
    // console.log("commision",commissionPercentage)
    // setFormData({...formData, commisionF:commissionPercentage})
  };
  const commissionValue = (c, i) => {
    if (data2.length > 0) {
      return (data2[i].Asp * c) / 100;
    } else {
      return 0; // or some default value
    }
  };
  const fixedFeesfind = (indexj, index) => {
    // for (let i = 0; i < fixedfeesM.length; i++) {
    // if (
    //   data2[index]["Vendor_Name"] === fixedfeesM[i]["Vendor_Name"] &&
    //   data2[index]["Brand_Name"] === fixedfeesM[i]["Brand_Name"] &&
    //   data2[index]["Gender"] === fixedfeesM[i]["Gender"] &&
    //   data2[index]["Sub_category"] === fixedfeesM[i]["Sub_category"]
    // ) {
    return data2[index]["Asp"] <= 500
      ? fixedfeesM[0]["rate"]
      : data2[index]["Asp"] > 500 && data2[index]["Asp"] <= 750
      ? fixedfeesM[1]["rate"]
      : data2[index]["Asp"] > 750 && data2[index]["Asp"] <= 1000
      ? fixedfeesM[2]["rate"]
      : data2[index]["Asp"] > 1000 && data2[index]["Asp"] <= 2000
      ? fixedfeesM[3]["rate"]
      : data2[index]["Asp"] > 2000
      ? fixedfeesM[4]["rate"]
      : null;
    // } else {
    //   console.log("fixed fees not match");
    // }
    // }
    //   console.log("fixedFees",fixedFees)
    //  setFormData({...formData,fixedfeesF:fixedFees})
  };
  const paymentFeesfind = (i) => {
    return (data2[i]["Asp"] * 0.02) / (1 - data2[i]["Customer Returns"] / 100);
    // console.log("paymentFees",paymentFees)
    // setFormData({...formData, paymentFeesF:paymentFees})
  };
  const forwardGet = (i, index) => {
    return forwardd(i, index);
  };
  const fff = (s, i) => {
    return s / (1 - data2[i]["Customer Returns"] / 100);
  };
  const reversechargesGett = (i) => {
    const reversechargesget = vbnhtre(i);
    console.log("reversechargesget", reversechargesget);
    return (
      (reversechargesget * (data2[i]["Customer Returns"] / 100)) /
      (1 - data2[i]["Customer Returns"] / 100)
    );
  };

  const Pick = (i) => {
    const a = fee(i);
    console.log(a);
    const b = data2[i]["Customer Returns"]/100
    // console.log(b);
    // console.log(a/(1-b));
    return a/(1-b)
  };

  const fee = (i) => {
    return level[i] == 1
      ? pick[0]["rate"]
      : level[i] == 2
      ? pick[1]["rate"]
      : level[i] == 3
      ? pick[2]["rate"]
      : level[i] == 4
      ? pick[3]["rate"]
      : level[i] == 5
      ? pick[4]["rate"]
      : null;
  };

  const taxget = (p3, p4, p5, p6, p7,pickandpack) => {
    console.log(p3, p4, p5, p6, p7);
    return (p3 + Number(p4) + p5 + p6 + p7 +pickandpack) * 0.18;
  };
  const paymentReceipt = (p3, p4, p5, p6, p7, p8, i,pickandpack) => {
    return data2[i]["Asp"] - (p3 + Number(p4) + p5 + p6 + p7 + p8+ pickandpack);
  };

  function forwardd(i, index) {
    if (forwardfeesM.length > 0) {
      console.log(level[index]);
      if (level[index] == 1) {
        console.log("yes arrived 1");
        // return forwardfeesM[0]["__EMPTY_2"]
        return forwardfeesM[0]["National"];
      }
      if (level[index] == 2) {
        console.log("yes arrived 2");

        return forwardfeesM[1]["National"];
      }
      if (level[index] == 3) {
        console.log("yes arrived 3");

        return forwardfeesM[2]["National"];
      }
      if (level[index] == 4) {
        console.log("yes arrived 4");

        return forwardfeesM[3]["National"];
      }
      if (level[index] == 5) {
        console.log("yes arrived 5");

        return forwardfeesM[4]["National"];
      }

      // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[7]["column_6"];
      // }
      // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[8]["column_6"];
      // }
      // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[9]["column_6"];
      // }
      // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[10]["column_6"];
      // }
      // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[11]["column_6"];
      // }
    }
  }
  const handleClick = (e) => {
    setIsDisabled(true);
    mrp.push(e.target.value);
  };
  // function adding(e) {
  //   discount.push(e.target.value);
  // }
  function aspAdd() {
    if (isDisabled) {
      // console.log(formData.data0);
      // console.log(formData.data1);
      // console.log(formData);
      return (formData.data0 * (100 - formData.data1)) / 100;
      // console.log(formData.data0 * (100 - formData.data1) / 100)
    }
  }
  function vbnhtre(i) {
    if (level[i] == 1) {
      // return forwardfeesM[0]["__EMPTY_2"]
      return reversefeesM[0]["National"];
    }
    if (level[i] == 2) {
      return reversefeesM[1]["National"];
    }
    if (level[i] == 3) {
      return reversefeesM[2]["National"];
    }
    if (level[i] == 4) {
      return reversefeesM[3]["National"];
    }
    if (level[i] == 5) {
      return reversefeesM[4]["National"];
    }

    // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[6]["column_6"];
    // }
    // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[7]["column_6"];
    // }
    // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[8]["column_6"];
    // }
    // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[9]["column_6"];
    // }
    // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[10]["column_6"];
    // }
  }
  function abc() {
    console.log("yesss");
    const abcd = (formData.data0 * formData.data1) / 100;
    return formData.data0 - abcd;
  }
  const handleFileUpload2 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2(parsedData);
      // };
    };
  };
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data2);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Result-file.xlsx");
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };
  const downloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const worksheet2 = workbook.addWorksheet("Sheet 2");

    worksheet.addRow([
      "Vendor_Name",
      "Brand_Name",
      "Gender",
      "Sub_category",
      "Asp",
      "Customer Returns",
      "Payment Receipt",
    ]);
    worksheet.addRow([
      "Sathyam Textiles",
      "THE HOLLANDER",
      "Men",
      "Tshirts",
      "487",
      "20",
      "0",
    ]);
    // worksheet.addRow(['Jane Doe', 25, '']);

    const Vendor_Name = [
      "Bedbathandbliss",
      "Brand Fortunes",
      "Dhanalaxmi Fashions",
      "Eclat Globalbiz LLP",
      "GIVO FASHIONS PRIVATE LIMITED",
      "Global Elle",
      "IMPERIUM INC",
      "INDI KRITI",
      "KULTPRIT Marketing Commerce Pvt Ltd",
      "LITTLE THREADS",
      "M/S HOMERZ SOLUTION",
      "Mohanlal Sons",
      "Network Clothing Company Pvt Ltd",
      "Opus Fashions Pvt. Ltd",
      "ORI VENTURES PRIVATE LIMITED",
      "Paras Textiles",
      "RISHIKA FASHIONS PVT LTD",
      "Sathyam Textiles",
      "SHARMA SALES",
      "Shree Rashmi Dresses",
      "SIVARAJ SPINNING MILLS (P) LTD",
      "Tailor and Circus Clothing Pvt Ltd",
      "Varnalaya Clothing",
    ];

    const Brand_Name = [
      "&CIRCUS",
      "Alaya",
      "American Bull",
      "Anouk",
      "Ariel",
      "Bedbathandbliss",
      "Braveo",
      "Chennis",
      "DEYANN",
      "DEYANN PLUS",
      "Enviously Young",
      "Ethniks Neu-Ron",
      "Givo",
      "Homerz",
      "House of Pataudi",
      "IMPACKT",
      "Inkriti",
      "Jansons",
      "Klephant",
      "Kryptic",
      "KULTPRIT",
      "La Trove",
      "LAMAAYA",
      "Little Chieftains",
      "Maybell",
      "MOHANLAL SONS",
      "Nifty",
      "Ode by House of Pataudi",
      "pivot",
      "Polka Tots",
      "Purple State",
      "Rishika",
      "SARSO",
      "SEIYON",
      "SEYBIL",
      "SI2 SLIP IN 2",
      "Steenbok",
      "Sztori",
      "Tailor & Circus",
      "THE HOLLANDER",
      "THE SOUL PATROL",
      "Twin birds",
      "WHITE HEART",
    ];

    const gender = [
      "Boys",
      "Girls",
      "Kids-boys",
      "Men",
      "Unisex",
      "Unisex Kids",
      "Women",
    ];

    const category = [
      "Baby Carriers",
      "Baby Gear & Nursery",
      "Bath Towels",
      "Bath Tub",
      "Bedsheets",
      "Bibs",
      "Blankets Quilts and Dohars",
      "Blazers",
      "Bodysuit",
      "Boxers",
      "Bra",
      "Briefs",
      "Camisoles",
      "Capris",
      "Churidar",
      "Clothing Set",
      "Co-Ords",
      "Dhotis",
      "Diaper Bags",
      "Diapers",
      "Dinner Set",
      "Diwan Set",
      "Dresses",
      "Dungarees",
      "Dupatta",
      "Ethnic Dresses",
      "Feeding Essentials",
      "Hand Towels",
      "Harem Pants",
      "Innerwear Vests",
      "Jackets",
      "Jeans",
      "Jeggings",
      "Jumpsuit",
      "Kitchen Towels",
      "Kurta Sets",
      "Kurtas",
      "Laundry Bag",
      "Leggings",
      "Lehenga Choli",
      "Lounge Pants",
      "Lounge Shorts",
      "Lounge Tshirts",
      "Nehru Jackets",
      "Night suits",
      "Nightdress",
      "Organisers",
      "Palazzos",
      "Patiala",
      "Pillow Covers",
      "Pillows",
      "Rompers",
      "Saree Blouse",
      "Shapewear",
      "Shawl",
      "Sherwani",
      "Shirts",
      "Shorts",
      "Shrug",
      "Skirts",
      "Sweatshirts",
      "Table Covers",
      "Tent",
      "Tights",
      "Tops",
      "Track Pants",
      "Trousers",
      "Trunk",
      "Tshirts",
    ];

    for (let i = 0; i < category.length; i++) {
      worksheet2.addRow([
        Vendor_Name[i] === undefined ? "" : Vendor_Name[i],
        Brand_Name[i] === undefined ? "" : Brand_Name[i],
        gender[i] === undefined ? "" : gender[i],
        category[i],
      ]);
    }

    for (let i = 2; i <= 250; i++) {
      const cell = worksheet.getCell(`A${i}`);
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$A$1:$A$16"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell2 = worksheet.getCell(`B${i}`);
      cell2.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$B$1:$B$25"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell3 = worksheet.getCell(`C${i}`);
      cell3.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$C$1:$C$8"],
      };
    }
    for (let i = 2; i <= 250; i++) {
      const cell4 = worksheet.getCell(`D${i}`);
      cell4.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["='Sheet 2'!$D$1:$D$65"],
      };
    }
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template-file.xlsx");
      })
      .catch((err) => console.error("Error writing Excel file:", err));
  };

  console.log(data2);
  console.log(commissionM);
  return (
    // <form action="" method="get" className="form-example padding-top">
    //   <div className="gamut-14z39fp-LayoutGrid 14g39">
    //     {formData.xcv && (
    //       <div className="form-example1">
    //         <label className="myntra" for="data0">
    //           Enter MRP:{" "}
    //         </label>
    //         <input
    //           type="text"
    //           name="data0"
    //           id="0011"
    //           onChange={(e) => {
    //             setFormData({
    //               ...formData,
    //               data0: parseInt(e.target.value),
    //               zlc: false,
    //             });
    //             handleClick(e);
    //           }}
    //         />

    //         <label className="myntra" for="data1">
    //           Enter Discount:{" "}
    //         </label>
    //         <input
    //           type="text"
    //           name="data1"
    //           id="0011"
    //           onChange={(e) => {
    //             setFormData({ ...formData, data1: parseInt(e.target.value) });
    //           }}
    //         />

    //         <button
    //           className="form-example23"
    //           onClick={(e) => {
    //             setFormData({ ...formData, ASP: aspAdd(), xcv: false });
    //           }}
    //           role="button"
    //         >
    //           Calc
    //         </button>
    //       </div>
    //     )}
    //     <div className="form-example">
    //       <p>Selling Price: {formData.ASP}</p>
    //       <label className="myntra" for="data3">
    //         Enter ASP:{" "}
    //       </label>
    //       <input
    //         type="text"
    //         name="data3"
    //         id="0011"
    //         value={formData.ASP || 0}
    //         onChange={(e) => {
    //           setFormData({
    //             ...formData,
    //             ASP: parseInt(e.target.value),
    //             xcv: false,
    //           });
    //         }}
    //         disabled={isDisabled}
    //       />
    //     </div>

    //     <div className="form-example">
    //       <label className="myntra" for="data2">
    //         Enter Customer Returns:{" "}
    //       </label>
    //       <input
    //         type="text"
    //         name="data2"
    //         id="0011"
    //         onChange={(e) =>
    //           setFormData({ ...formData, data2: parseInt(e.target.value) })
    //         }
    //         required
    //       />
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataB">
    //         {" "}
    //         Vendor Name:
    //       </label>

    //       <select
    //         name="dataB"
    //         id="dataB"
    //         value={formData.dataB}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataB: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[...new Set(commissionM.map((data) => data["Vendor_Name"]))].map(
    //           (vendorName, index) => (
    //             <option key={index} value={vendorName}>
    //               {vendorName}
    //             </option>
    //           )
    //         )}
    //       </select>
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataC">
    //         Brand Name:
    //       </label>

    //       <select
    //        name="dataC"
    //        id="dataB"
    //        value={formData.dataC}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataC: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[
    //           ...new Set(
    //             commissionM
    //               .filter((data) => data["Vendor_Name"] == formData.dataB)
    //               .map((data) => data["Brand"])
    //           ),
    //         ].map((data, index) => (
    //           <option key={index} value={data}>
    //             {data}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //     <div className="form-example">
    //       <label className="myntra" for="dataG">
    //         Gender:
    //       </label>

    //       <select
    //         name="dataG"
    //         id="dataB"
    //         value={formData.dataG}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataG: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {[
    //           ...new Set(
    //             commissionM
    //               .filter((data) => data["Brand"] == formData.dataC)
    //               .map((data) => data["Gender"])
    //           )
    //         ].map((data, index) => (
    //           <option key={index} value={data}>
    //             {data}
    //           </option>
    //         ))}
    //       </select>
    //     </div>

    //     <div className="form-example">
    //       <label className="myntra" for="dataD">
    //         Category:
    //       </label>

    //       <select
    //         name="dataD"
    //         id="dataB"
    //         value={formData.dataD}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataD: e.target.value })
    //         }
    //       >
    //         <option value="">--Please choose an option--</option>
    //         {commissionM
    //           .filter(
    //             (data) =>
    //               data["Gender"] == formData.dataG &&
    //               data["Brand"] == formData.dataC
    //           )
    //           .map((data, index) => (
    //             <option key={index} value={data["ArticleType"]}>
    //               {data["ArticleType"]}
    //             </option>
    //           ))}
    //       </select>
    //     </div>

    //     <button
    //       className="form-example23"
    //       onClick={(e) => {
    //         handleFormSubmit(e);
    //       }}
    //       role="button"
    //     >
    //       Calc
    //     </button>

    //     <div className="form-example">
    //       <label className="myntra" for="dataPV">
    //         Payment Receipt:{" "}
    //       </label>
    //       <input
    //         type="number"
    //         name="dataPV"
    //         id="0011"
    //         value={pv}
    //         onChange={(e) =>
    //           setFormData({ ...formData, dataPV: e.target.value })
    //         }
    //         required
    //       />
    //     </div>
    //   </div>
    //   <div id="transformers">
    //     <button id="ksi" onClick={() => window.location.reload()}>
    //       <span id="text">Reload</span>
    //     </button>
    //   </div>
    // </form>
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Myntra SJIT</b>
        </h3>
      </div>

      <section class="u-section-container">
        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload2(e); // Call the handleFileUpload2 function here
            }}
          />
          <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div>
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  {/* Number of Reports for August 2024 :{" "} */}
                  Payment Receipt Finder :
                </h3>
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                            {/* <th
                            rowspan="1"
                            colspan="1"
                            class=""
                            style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Documents
                          </th> */}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}

                        {/* <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                             style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td> */}

                        {/* <tr>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Order Flow
                          </td>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                             style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            OTHERS INVOICE
                          </td>
                          <td
                            class=""
                            style={{"--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            <button
                              tabindex="0"
                              type="button"
                              class="u-button-container regular u-button-text"
                              role="button"
                              data-test-id="target"
                              style={{"text-transform": "uppercase"}}
                            >
                              Download
                            </button>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyntraforwardSJIT;
