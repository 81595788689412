import React from "react";
// import Details from './Details.json'
// import Commision from './Commision.json'
// import Forward from "./Forward.json";
// import reverse from "./ReverseLOg.json";
import { useState, useEffect } from "react";
import axios from "axios";

const Testing4 = (props) => {
  const [Details, setDetails] = useState([]);
  const [Commision, setCommision] = useState([]);
  const [Forward, setForward] = useState([]);
  const [reverse, setReverse] = useState([]);
  const [fixedfees, setFixedfees] = useState([]);
  const [pick, setPick] = useState([]);

  console.log(Commision);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/calmyndetails");
        // console.log("Response:", response.data);
        setDetails(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData1() {
      try {
        const response = await axios.get("/api/calmyncommission");
        // console.log("Response:", response.data);
        setCommision(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData2() {
      try {
        const response = await axios.get("/api/MyntraSJITforward");
        // console.log("Response:", response.data);
        setForward(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData3() {
      try {
        const response = await axios.get("/api/MyntraSJIT_reverse_fee");
        // console.log("Response:", response.data);
        setReverse(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    async function fetchData4() {
      try {
        const response = await axios.get("/api/MyntraSJITfixedfees");
        // console.log("Response:", response.data);
        setFixedfees(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/pickandpackfee");
        // console.log("Response:", response);
        setPick(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);

  let level00 = [];
  let storage = {};
  let Result = [];
  let Discount = [];
  console.log(Commision);
  console.log(props.data2);
  function step1() {
    console.log("yes");
    const Q = Commision;
    const W = props.data2;
    console.log(W);
    for (let i = 0; i < W.length; i++) {
      const Wl = W[i]["Vendor Name"].toLowerCase();
      const El = W[i]["Brand Name"].toLowerCase();
      const Fl = W[i]["Gender"].toLowerCase();
      const Gl = W[i]["Sub-category"].toLowerCase();
      const paymentRealized = W[i]["Payment Realized"];
      for (let j = 0; j < Q.length; j++) {
        const Sub = Q[j]["Sub_category"].toLowerCase();
        const Hl = Q[j]["Brand_Name"].toLowerCase();
        const Il = Q[j]["Gender"].toLowerCase();
        const Kl = Q[j]["Vendor_Name"].toLowerCase();

        if (Wl == Kl && El == Hl && Fl == Il && Gl == Sub) {
          console.log(W[i], Q[j]);
          console.log("match1=", j);
          packages(i, j, paymentRealized, Q);
          packages2(i, j, paymentRealized, Q, El);
          break;
        } else {
          // console.log(W[i],Q[j]);
          console.log("not match");
        }
      }
    }
    const updatedData = props.data2.map((item, index) => {
      return { ...item, ASP: Result[index], Discount: Discount[index] };
    });
    props.setData2(updatedData);
  }

  ////////////////pakages/////////////////
  function packages(i, j, paymentRealized, Q) {
    // //console.log("j", j)
    const maximumval = Commision[j]["Commission_"];
    console.log(maximumval);
    let b = maximumval / 100;
    let c = b * 100;
    let d = 100 - c;
    const asp1 = (paymentRealized / d) * 100;
    const keyName1 = `asp${i}`;
    storage[keyName1] = asp1;

    const keyName2 = `j${i}`;
    storage[keyName2] = j;
    const keyName3 = "Level";
    storage[keyName3] = Q[j]["Article_level"];
    console.log("storage", storage);
    console.log(Q[j]["Article_level"]);
    level00.push(
      Q[j]["Article_level"] === undefined ? "undefined" : Q[j]["Article_level"]
    );
  }

  function packages2(i, j, paymentRealized, Q, Bl) {
    // let find = `asp${i}`;

    //     let findj = `j${i}`;
    //     let findji= storage[findj];
    //     const aaa = Q[findji]['0 - 300'];
    //     const bb = Q[findji]['300 - 500'];
    //     const cc = Q[findji]['500 - 800'];
    //     const ddd = Q[findji]['800-1000'];
    //     const ee = Q[findji]['1000-1500'];
    //     const ff = Q[findji]['1500-2500'];
    //     const gg = Q[findji]['>2500'];
    //     const hh = Q[findji].Gender;
    //     const ii = Q[findji]['Sub-category'];

    //     animals.push(aaa,bb,cc,ddd,ee,ff,gg)
    //     for (let i =0; i< animals.length;i++){
    //     const val0 = (animals[i]*100)
    //     const val1 =(100-val0)
    //     const aspc= (paymentRealized/val1)*100
    //     arr.push(aspc)
    //     }
    //     let minValue= Math.min(...arr);
    //     console.log("Minimum element is:" + minValue);
    //     let dd = perFinder(findji,minValue)
    //     console.log("perFinder",dd);
    //     commision.push(dd);

    //   // let val0 = (dd*100);
    //   // let z=  Math.round((paymentRealized/(100-val0))*100)
    //   //// //console.log("final asp:"+ z)
    //   //Result.push(z)
    if (Bl === "Krypticv") {
      const comm = Commision[0].Commission;
      const s = Math.round(newton(paymentRealized, i, comm, j, props.data2));
      console.log("newton raphsons Kryptic", s);
      Result.push(s);
    } else {
      const comm1 = Commision[j]["Commission_"];
      const comm2 = comm1 / 100;
      const s = Math.round(newton(paymentRealized, i, comm2, j, props.data2));
      console.log("newton raphsons", s);
      Result.push(s);
    }

    let a2 = props.data2[i].MRP - Result[i];
    let b2 = Math.round((a2 / props.data2[i].MRP) * 100);
    Discount.push(b2);
    console.log((a2 / props.data2[i].MRP) * 100);
  }

  //////////newton raphson////////////////////
  // function newton (payment,i,commision,j){
  //     // Function to calculate the result based on the given values of C6, C8, C10, C11, C12, C13, and C14
  //     function calculateResult(C6, C8, C10, C11, C12, C13, C14) {
  //       return C6 - C8 - C10 - C11 - C12 - C13 - C14;
  //     }

  //     // Function to calculate the derivative of the result with respect to C6
  //     function calculateDerivative(C6, C7, C8, C9, C10, C11, C12, C13, C14) {
  //       // Central difference method for numerical derivative
  //       const h = 0.0001; // Step size
  //       return (calculateResult(C6 + h, C8, C10, C11, C12, C13, C14) - calculateResult(C6 - h, C8, C10, C11, C12, C13, C14)) / (2 * h);
  //     }

  //     // Function to perform Newton-Raphson method to find the value of C6
  //     function newtonRaphson(C7, C9, initialGuess, tolerance, targetResult) {
  //       let C6 = initialGuess;
  //       let C8, C10, C11, C12, C13, C14, result, derivative;

  //       do {
  //           C8 = C7 * C6;
  //           C10 = calculateC10(C6,j);
  //           C11 = calculateC11(C6, C9,j);
  //           C12 = calculateC12(C6, C9);
  //           C13 = calculateC13(C9);
  //           C14 = calculateC14(C6, C9, C7, C8, C10, C11, C12, C13);

  //           result = calculateResult(C6, C8, C10, C11, C12, C13, C14);
  //           derivative = calculateDerivative(C6, C7, C8, C9, C10, C11, C12, C13, C14);

  //           C6 -= (result - targetResult) / derivative;
  //       } while (Math.abs(result - targetResult) > tolerance);

  //       return C6;
  //     }

  //     // Function to calculate C10 based on C6
  //     function calculateC10(C6,j) {
  //       const Q =fixedfees ;
  //       const aaa = Q[j]["0 - 300"];
  //         const bb = Q[j]["300 - 500"];
  //         const cc = Q[j]["500 - 1000"];
  //         const ddd = Q[j]["> 1000"];

  //     if (C6 <= 300 && C6 >= 0) {

  //           const a1 = aaa;
  //           console.log("a1", a1)

  //           // //console.log(a1)
  //           return a1/(1-C9)
  //         }
  //       if (C6 <= 500 && C6 > 300) {

  //         const a1 = bb;
  //         console.log("a1", a1)
  //         return a1/(1-C9)  }

  //       if (C6 <= 1000 && C6 > 500) {
  //         const a1 = cc;
  //         console.log("a1", a1)
  //         // console.log("C9",C9)
  //         // console.log("vaaaaa")
  //         return a1/(1-C9)
  //         // console.log("vaaaaa",a1/(1-C9))
  //       }

  //       if (C6 > 1000) {
  //         // //console.log("jkdhcjdjksahkjskahk")
  //         const a1 =ddd;
  //         console.log("a1", a1)

  //         return a1/(1-C9)  }
  //     }

  //     // Function to calculate C11 based on C6 and C9
  //     function calculateC11(C6, C9,j) {
  //       if (C6 <= 750) {
  //         const v= collectionfees[j].Prepaid;
  //         console.log("v",v)
  //         return  v/(1-C9)
  //       }
  //       if (C6 > 750) {
  //         const v = collectionfees[j].__EMPTY_2;
  //         console.log("v",v)
  //         return  (C6 * v) / (1 - C9)
  //       }
  //     }

  //     // Function to calculate C12 based on C6 and C9
  //     function calculateC12(C6, C9) {
  //       const s= shippingfees[0].National
  //       return s / (1 - C9);
  //     }

  //     // Function to calculate C13 based on C9
  //     function calculateC13(C9) {
  //       const l = reversefees[0].National
  //       return l * C9 / (1 - C9);
  //     }

  //     // Function to calculate C14 based on C6, C9, C7, and other values
  //     function calculateC14(C6, C9, C7, C8, C10, C11, C12, C13) {
  //       return (C8 + C10 + C11 + C12 + C13) * 0.18;
  //     }

  //     // Example values for C7 and C9
  //     const C7 = commision; // Example value for C7
  //     const C9 = props.data2[i]["Customer Returns"]; // Example value for C9

  //     // Initial guess for C6 and tolerance
  //     const initialGuess = 1;
  //     const tolerance = 0.1;

  //     // Target result
  //     const targetResult = payment;

  //     // Finding the value of C6
  //     const goalSeekResult = newtonRaphson(C7, C9, initialGuess, tolerance, targetResult);

  //     // Printing the result
  //     console.log("Goal Seek Result (C6):", goalSeekResult);
  //     return goalSeekResult

  //     }

  function newton(payment, i, commision, j, data2) {
    console.log("parameter", payment, i, commision, j);
    function calculateResult(B4, B5, B6, B7) {
      // Calculate B8 based on B4
      const B8 = () => {
        console.log(B4, fixedfees[0]["rate"]);
        return B4 <= 500
          ? fixedfees[0]["rate"]
          : B4 > 500 && B4 <= 750
          ? fixedfees[1]["rate"]
          : B4 > 750 && B4 <= 1000
          ? fixedfees[2]["rate"]
          : B4 > 1000 && B4 <= 2000
          ? fixedfees[3]["rate"]
          : B4 > 2000
          ? fixedfees[4]["rate"]
          : null;
        // for (let i = 0; i < fixedfees.length; i++) {
        //   if (
        //     data2[index]["Vendor_Name"] === fixedfees[i]["Vendor_Name"] &&
        //     data2[index]["Brand_Name"] === fixedfees[i]["Brand_Name"] &&
        //     data2[index]["Gender"] === fixedfees[i]["Gender"] &&
        //     data2[index]["Sub_category"] === fixedfees[i]["Sub_category"]
        //   ) {
        //     return B4 > 2000
        //       ? fixedfees[j + 1][">2000"]
        //       : B4 > 1000 && B4 <= 2000
        //       ? fixedfees[j + 1]["1000-2000"]
        //       : B4 <= 1000 && B4 > 750
        //       ? fixedfees[j + 1]["750-1000"]
        //       : B4 <= 750 && B4 > 500
        //       ? fixedfees[j + 1]["500-750"]
        //       : B4 <= 500
        //       ? fixedfees[j + 1]["0-500"]
        //       : null;
        //   } else {
        //     console.log("fixed fees not match");
        //   }
        // }
      };

      const fixedfeesfornewton = B8();
      console.log("b8", fixedfeesfornewton);

      // Calculate B9, B10, B11 based on B4 and B7
      console.log(B7, B4);

      const B9 = (B4 * 0.02) / (1 - B7);
      console.log("b9", B9);

      const BB10 = () => {
        console.log("hiiiiiiiiii");
        console.log(level00);
        console.log(storage);
        const index = i;

        // if (storage.Level == 1) {
        //   const as = Forward[1]["PPMP1st_Unit_National"];
        //   return as / (1 - B7);
        // } else if (storage.Level == 2) {
        //   const as = Forward[2]["PPMP1st_Unit_National"];
        //   return as / (1 - B7);
        // } else if (storage.Level == 3) {
        //   const as = Forward[3]["PPMP1st_Unit_National"];
        //   return as / (1 - B7);
        // } else if (storage.Level == 4) {
        //   const as = Forward[4]["PPMP1st_Unit_National"];
        //   return as / (1 - B7);
        // } else if (storage.Level == 5) {
        //   const as = Forward[5]["PPMP1st_Unit_National"];
        //   return as / (1 - B7);
        // }
        if (level00[index] == 1) {
          console.log("yes arrived 1");
          // return forwardfeesM[0]["__EMPTY_2"]
          return Forward[0]["National"];
        }
        if (level00[index] == 2) {
          console.log("yes arrived 2");

          return Forward[1]["National"];
        }
        if (level00[index] == 3) {
          console.log("yes arrived 3");

          return Forward[2]["National"];
        }
        if (level00[index] == 4) {
          console.log("yes arrived 4");

          return Forward[3]["National"];
        }
        if (level00[index] == 5) {
          console.log("yes arrived 5");

          return Forward[4]["National"];
        }
      };
      const B10 = BB10();
      const x = data2[i]["Customer Returns"] / 100;
      const ansB10 = B10 /(1-x)

      console.log("b10", ansB10);

      const BB11 = () => {
        // if (storage.Level == 1) {
        //   const as = reverse[1]["PPMP1st_Unit_National"];
        //   console.log("as", as);
        //   return (as * B7) / (1 - B7);
        // } else if (storage.Level == 2) {
        //   const as = reverse[2]["PPMP1st_Unit_National"];
        //   return (as * B7) / (1 - B7);
        // } else if (storage.Level == 3) {
        //   const as = reverse[3]["PPMP1st_Unit_National"];
        //   return (as * B7) / (1 - B7);
        // } else if (storage.Level == 4) {
        //   const as = reverse[4]["PPMP1st_Unit_National"];
        //   return (as * B7) / (1 - B7);
        // } else if (storage.Level == 5) {
        //   const as = reverse[5]["PPMP1st_Unit_National"];
        //   return (as * B7) / (1 - B7);
        // }
        if (level00[i] == 1) {
          // return forwardfeesM[0]["__EMPTY_2"]
          return reverse[0]["National"];
        }
        if (level00[i] == 2) {
          return reverse[1]["National"];
        }
        if (level00[i] == 3) {
          return reverse[2]["National"];
        }
        if (level00[i] == 4) {
          return reverse[3]["National"];
        }
        if (level00[i] == 5) {
          return reverse[4]["National"];
        }
      };
      const B11 = BB11();
      const y = data2[i]["Customer Returns"] / 100;
      const ansB11 = (B11*y) /(1-y)

      console.log("b11", ansB11);

      const Pick = (i) => {
        const a = fee(i);
        console.log(a);
        const b = data2[i]["Customer Returns"] / 100;
        // console.log(b);
        // console.log(a/(1-b));
        return a / (1 - b);
      };
      const fee = (i) => {
        return level00[i] == 1
          ? pick[0]["rate"]
          : level00[i] == 2
          ? pick[1]["rate"]
          : level00[i] == 3
          ? pick[2]["rate"]
          : level00[i] == 4
          ? pick[3]["rate"]
          : level00[i] == 5
          ? pick[4]["rate"]
          : null;
      };
      const pickpack = Pick(i);
      console.log("pickpack",pickpack)

      // Calculate B12 based on B6, B8, B9, B10, B11
      const B12 = (B6 + fixedfeesfornewton + B9 + ansB10 + ansB11+ pickpack) * 0.18;

      // Calculate the result
      console.log(B4,B5,B6,B7,fixedfeesfornewton,B9,ansB10,ansB11,pickpack);
      // console.log(B4, B5, fixedfeesfornewton, B9, ansB10, B11, B12);
      return B4 - B4 * B5 - fixedfeesfornewton - B9 - ansB10 - ansB11 - B12- pickpack;
    }

    // Function to calculate the derivative of the result with respect to B4
    function calculateDerivative(B4, B5, B6, B7) {
      const h = 0.0001; // Step size
      return (
        (calculateResult(B4 + h, B5, B6, B7) -
          calculateResult(B4 - h, B5, B6, B7)) /
        (2 * h)
      );
    }
      
    // Function to perform Newton-Raphson method to find the value of B4
    function newtonRaphson(B5, B6, B7, initialGuess, tolerance, targetResult) {
      let B4 = initialGuess;
      let result, derivative;

      do {
        // Calculate B6 based on B4
        const B6 = B5 * B4;
        console.log("B6",B6);

        // Calculate the result and derivative
        result = calculateResult(B4, B5, B6, B7);
        derivative = calculateDerivative(B4, B5, B6, B7);
        console.log(result, derivative, B4);
        // Update B4 using Newton-Raphson method
        B4 -= (result - targetResult) / derivative;
      } while (Math.abs(result - targetResult) > tolerance);

      return B4;
    }

    // Example values for B5 and B7
    const B5 = commision; // Example value for B5
    const B7 = props.data2[i]["Customer Returns"] / 100; // Example value for B7
    console.log("commision", B5);
    console.log("customer returns", B7);

    // Initial guess for B4 and tolerance
    const initialGuess = 1;
    const tolerance = 0.0001;

    // Target result
    const targetResult = payment; // Adjust according to your goal
    console.log("payment", targetResult);

    // Finding the value of B4 using Newton-Raphson method
    const goalSeekResult = newtonRaphson(
      B5,
      B5 * initialGuess,
      B7,
      initialGuess,
      tolerance,
      targetResult
    );
    return goalSeekResult;
    // Printing the result
    // console.log("Goal Seek Result (B4):", goalSeekResult);
  }
  return (
    <div>
      <h1 className="flipkart">Myntra SJIT</h1>

      <button id="xyz" onClick={step1}>
        Calc ASP
      </button>
      {/* <Newton data = {res} state2 = {props.data2} ></Newton> */}
    </div>
  );
};
export default Testing4;
