import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [input0, setInput0] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [error, setError] = useState(false);
  const [jsonOutput, setJsonOutput] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [newJD, setNewJD] = useState([]);
  const [sales, setSales] = useState([]);
  const [returns, setReturns] = useState([]);
  const [highlight2, setHighlight2] = useState([]);
  console.log(sales);
  console.log(returns);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let jsonDataz = [...jsonData]; // Create a shallow copy of jsonData
    console.log(jsonDataz.length);

    let res;
    let res2;
    let newJDA = [];
    for (let i = 0; i < jsonData.length; i++) {
      if (jsonData[i].fileName.includes("B2B")) {
        res = "B2B";
        const G = jsonData[i].data;
        const H = G.map((item) => {
          return { ...item, Model: res };
        });
        console.log(H);
        setNewJD((pre) => [...pre, H]);
        newJDA.push(H);
      }

      if (jsonData[i].fileName.includes("B2C")) {
        res2 = "B2C";
        const G = jsonData[i].data;
        console.log(G);
        const keys = [
          "Seller Gstin",
          "Invoice Number",
          "Invoice Date",
          "Transaction Type",
          "Order Id",
          "Shipment Id",
          "Shipment Date",
          "Order Date",
          "Shipment Item Id",
          "Quantity",
          "Item Description",
          "Asin",
          "Hsn/sac",
          "Sku",
          "Product Tax Code",
          "Bill From City",
          "Bill From State",
          "Bill From Country",
          "Bill From Postal Code",
          "Ship From City",
          "Ship From State",
          "Ship From Country",
          "Ship From Postal Code",
          "Ship To City",
          "Ship To State",
          "Ship To Country",
          "Ship To Postal Code",
          "Invoice Amount",
          "Tax Exclusive Gross",
          "Total Tax Amount",
          "Cgst Rate",
          "Sgst Rate",
          "Utgst Rate",
          "Igst Rate",
          "Compensatory Cess Rate",
          "Principal Amount",
          "Principal Amount Basis",
          "Cgst Tax",
          "Sgst Tax",
          "Utgst Tax",
          "Igst Tax",
          "Compensatory Cess Tax",
          "Shipping Amount",
          "Shipping Amount Basis",
          "Shipping Cgst Tax",
          "Shipping Sgst Tax",
          "Shipping Utgst Tax",
          "Shipping Igst Tax",
          "Shipping Cess Tax Amount",
          "Gift Wrap Amount",
          "Gift Wrap Amount Basis",
          "Gift Wrap Cgst Tax",
          "Gift Wrap Sgst Tax",
          "Gift Wrap Utgst Tax",
          "Gift Wrap Igst Tax",
          "Gift Wrap Compensatory Cess Tax",
          "Item Promo Discount",
          "Item Promo Discount Basis",
          "Item Promo Tax",
          "Shipping Promo Discount",
          "Shipping Promo Discount Basis",
          "Shipping Promo Tax",
          "Gift Wrap Promo Discount",
          "Gift Wrap Promo Discount Basis",
          "Gift Wrap Promo Tax",
          "Tcs Cgst Rate",
          "Tcs Cgst Amount",
          "Tcs Sgst Rate",
          "Tcs Sgst Amount",
          "Tcs Utgst Rate",
          "Tcs Utgst Amount",
          "Tcs Igst Rate",
          "Tcs Igst Amount",
          "Warehouse Id",
          "Fulfillment Channel",
          "Payment Method Code",
          "Credit Note No",
          "Credit Note Date",
      ];
      const H = G.map((item) => {
        // Create a new object with the desired format
        const newItem = {};
        keys.forEach(key => {
            newItem[key] = item[key]; // Assign each key an array containing the key itself
        });
        
        // Optionally, you can add values from `item` if needed
        // For example:
        // newItem["Seller Gstin"] = item["Seller Gstin"]; // If you want to add actual values
    
        return {...newItem,"Customer Bill To Gstid" :" ",Model: res2};
    });
        console.log(H);
        setNewJD((pre) => [...pre, H]);
        newJDA.push(H);
      }
    }

    const input = newJDA;

    console.log(input); // for (let i = 0; i < jsonData.length; i++) {
    //   input.push(jsonData[i].data);
    // }
    console.log(input);
    console.log(newJD);
    const flatArray1 = input ? input.flat() : null;
    console.log(flatArray1);
    // const input = [[jsonData[0].data],[jsonData[1].data]]

    const updatedData2 = Array.isArray(flatArray1)
      ? flatArray1.map((item) => {
          const keysToRemove = [
            "Seller Gstin",
            // "Transaction Type",
            "Shipment Id",
            "Shipment Date",
            "Order Date",
            "Shipment Item Id",
            "Item Description",
            "Asin",
            "Product Tax Code",
            "Bill From City",
            "Bill From State",
            "Bill From Country",
            "Bill From Postal Code",
            "Ship From City",
            "Ship From State",
            "Ship From Country",
            "Ship From Postal Code",
            "Ship To City",
            "Ship To Country",
            "Ship To Postal Code",
            "Cgst Rate",
            "Sgst Rate",
            "Utgst Rate",
            "Igst Rate",
            "Compensatory Cess Rate",
            "Principal Amount",
            "Principal Amount Basis",
            "Compensatory Cess Tax",
            "Shipping Amount",
            "Shipping Amount Basis",
            "Shipping Cgst Tax",
            "Shipping Sgst Tax",
            "Shipping Utgst Tax",
            "Shipping Igst Tax",
            "Shipping Cess Tax",
            "Gift Wrap Amount",
            "Gift Wrap Amount Basis",
            "Gift Wrap Cgst Tax",
            "Gift Wrap Sgst Tax",
            "Gift Wrap Utgst Tax",
            "Gift Wrap Igst Tax",
            "Gift Wrap Compensatory Cess Tax",
            "Item Promo Discount",
            "Item Promo Discount Basis",
            "Item Promo Tax",
            "Shipping Promo Discount",
            "Shipping Promo Discount Basis",
            "Shipping Promo Tax",
            "Gift Wrap Promo Discount",
            "Gift Wrap Promo Discount Basis",
            "Gift Wrap Promo Tax",
            "Tcs Cgst Rate",
            "Tcs Cgst Amount",
            "Tcs Sgst Rate",
            "Tcs Sgst Amount",
            "Tcs Utgst Rate",
            "Tcs Utgst Amount",
            "Tcs Igst Rate",
            "Tcs Igst Amount",
            "Warehouse Id",
            "Fulfillment Channel",
            "Payment Method Code",
            "Bill To City",
            "Bill To State",
            "Bill To Country",
            "Bill To Postalcode",
            // "Customer Bill To Gstid",
            "Customer Ship To Gstid",
            "Buyer Name",
            "Credit Note No",
            "Credit Note Date",
            "Irn Number",
            "Irn Filing Status",
            "Irn Date",
            "Irn Error Code",
            "Shipping Cess Tax Amount",
          ];

          const jsonData1 = { ...item }; // Create a shallow copy of the item
          keysToRemove.forEach((key) => {
            delete jsonData1[key]; // Remove the keys from the copied object
          });
          return jsonData1; // Return the modified object
        })
      : (() => {
          console.error("data2 is not an array or is undefined.");
          return []; // Return an empty array or handle the error as needed
        })();

    console.log(updatedData2);
    const B = updatedData2.filter((item) => item["Invoice Number"] !== "");

    const C = B.map((item) => {
      const dateString = item["Invoice Date"];
      const month0 = dateString.split(" ")[0];

      // Split the date string into components
      const [year, month, day] = month0.split("-");

      return { ...item, "Invoice Date": `${day}-${month}-${year}` };
    });

    console.log(C);

    const R = C.filter((item) => {
      return item["Transaction Type"] !== "Refund";
    });

    console.log(R);
    const T = R.map((item) => {
      const { "Transaction Type": _, ...rest } = item;
      return rest;
    });
    setSales(T);

    const Y = C.filter((item) => {
      return (
        item["Transaction Type"] !== "FreeReplacement" &&
        item["Transaction Type"] !== "Shipment"
      );
    });

    console.log(Y);
    const U = Y.map((item) => {
      const { "Transaction Type": _, ...rest } = item;
      return rest;
    });
    setReturns(U);

    const i = T.map((item, index) => {
      if (item["Tax Exclusive Gross"] < 100) {
        setHighlight2((pre) => [...pre, index]);
        setError(true);
      }
    });

    // const C = B.map((item)=>
    //     item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // )
    // const C = B.map(item => item.SHIPPED_QUANTITY === "0" ? "1" : item.SHIPPED_QUANTITY);
    //   const C = B.map(item => ({
    //     ...item,
    //     SHIPPED_QUANTITY: item.SHIPPED_QUANTITY === "0" && item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // }));
    // const dateString = "Thu Aug 01 09:59:22 IST 2024";
    // const month = dateString.split(" ")[1]; // Split by spaces and get the second element
    // console.log(month); // Output: "Aug"
    // const E = C.map((item) => {
    //   // // const dateString = item.SELLER_INVOICE_DATE;
    //   // const dateString = "Oct 01 10:24:14 IST 2024";

    //   // // Create a Date object from the date string
    //   // const date = new Date(dateString);

    //   // // Format the date to "DD-MM-YYYY"
    //   // const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //   // const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //   // const year = date.getFullYear(); // Get full year

    //   // const formattedDate = `${day}-${month}-${year}`;
    //   // console.log(formattedDate, dateString, date); // Output: "01-10-2024"
    //   // return { ...item, SELLER_INVOICE_DATE: formattedDate };

    //   const dateString = item.SELLER_INVOICE_DATE;

    //   // Split the date string into components
    //   const parts = dateString.split(" ");
    //   const month = parts[1]; // "Oct"
    //   const day = parts[2]; // "01"
    //   const time = parts[3]; // "10:24:14"
    //   const year = parts[5]; // "2024"

    //   // Create a new date string in a more reliable format
    //   const formattedDateString = `${month} ${day} ${year} ${time}`;

    //   // Create a Date object
    //   const date = new Date(formattedDateString);

    //   // Check if the date is valid
    //   if (isNaN(date.getTime())) {
    //     console.error(`Invalid date: ${formattedDateString}`);
    //   } else {
    //     // Format the date to "DD-MM-YYYY"
    //     const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //     const year = date.getFullYear(); // Get full year

    //     const formattedDate = `${day}-${month}-${year}`;
    //     console.log(formattedDate); // Output: "01-10-2024"
    //     return { ...item, SELLER_INVOICE_DATE: formattedDate };
    //   }
    // });

    // const dateString = "Tue Oct 01 10:24:14 IST 2024";

    // // Split the date string into components
    // const parts = dateString.split(" ");
    // const month = parts[1]; // "Oct"
    // const day = parts[2]; // "01"
    // const time = parts[3]; // "10:24:14"
    // const year = parts[5]; // "2024"

    // // Create a new date string in a more reliable format
    // const formattedDateString = `${month} ${day} ${year} ${time}`;

    // // Create a Date object
    // const date = new Date(formattedDateString);

    // // Check if the date is valid
    // if (isNaN(date.getTime())) {
    //     console.error(`Invalid date: ${formattedDateString}`);
    // } else {
    //     // Format the date to "DD-MM-YYYY"
    //     const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //     const year = date.getFullYear(); // Get full year

    //     const formattedDate = `${day}-${month}-${year}`;
    //     console.log(formattedDate); // Output: "01-10-2024"
    // }

    // const D = E.map((item) => {
    //   const taxableValue =
    //     Number(item.TOTAL_VALUE) -
    //     (Number(item.CGST_AMOUNT) +
    //       Number(item.IGST_AMOUNT) +
    //       Number(item.SGST_AMOUNT));

    //   // Set error based on the taxable value
    //   console.log(taxableValue);
    //   if (taxableValue < 100) {
    //     setError(true);
    //   }

    //   return {
    //     PURCHASE_ORDER_NUMBER: item.PURCHASE_ORDER_NUMBER,
    //     Party_Name: "RELIANCE RETAIL LIMITED",
    //     GSTIN_Number: input2,
    //     SELLER_INVOICE_NO: item.SELLER_INVOICE_NO,
    //     SELLER_INVOICE_DATE: item.SELLER_INVOICE_DATE,
    //     BASE_PRICE: item.BASE_PRICE,
    //     TAXABLE_VALUE: taxableValue,
    //     ...item,
    //   };
    // });

    // setData2(D);
  };

  // const handleFileUpload2 = (e) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     // console.log(parsedData)
  //     setData2(parsedData);
  //     // };
  //   };
  // };

  // const downloadExcel = () => {
  //   const worksheet1 = XLSX.utils.json_to_sheet(sales);
  //   const worksheet2 = XLSX.utils.json_to_sheet(returns);

  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet1, "Sales");
  //   XLSX.utils.book_append_sheet(workbook, worksheet2, "Returns");

  //   XLSX.writeFile(workbook, "Amazon_GST_Report.xlsx");
  // };

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("sales");
    const worksheet2 = workbook.addWorksheet("Returns");

    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(sales[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(returns[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows
    sales.forEach((data, index) => {
      if (highlight2.includes(index)) {
        // Check if index is in the highlight array
        const headerRow2 = worksheet1.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet1.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Add 2 file data rows
    returns.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Amazon_GST_Report_${input2}.xlsx`);
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };

  //////////// file convert csv to json /////////////////////
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     convertCsvToJson(file, (data) => {
  //       setJsonData(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJson = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       // result.data will contain the JSON array
  //       callback(result.data);
  //     },
  //     error: (error) => {
  //       console.error("Error parsing CSV: ", error);
  //     },
  //   });
  // };
  //////////////////////////////////////////////////

  ////////////////file upload csv to json(multiple) ///////////////////////
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => parseCsvFile(file));

    // Wait for all files to be parsed and then set the JSON data
    Promise.all(promises).then((results) => {
      setJsonData(results);
    });
  };

  const parseCsvFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve({ fileName: file.name, data: result.data });
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          reject(error);
        },
      });
    });
  };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(error);
  console.log(data2);
  console.log(jsonData);
  console.log(newJD);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Amazon GST Reports</b>
        </h3>
      </div>
      <div className="sub-heading">
        <h3 className="subHead">Sales & Returns</h3>
      </div>

      <section class="u-section-container">
        <p className="paragraphTitle">B2B & B2C file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                  {error ? (
                    <div>
                      <h3 className="errorfor<200">
                        Tax Exclusive Gross value lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                            {/* <th
                            rowspan="1"
                            colspan="1"
                            class=""
                            style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Documents
                          </th> */}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
