import React, { createContext, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { setState } from "../../Data_adding_page/CounterSlice";






const ContextCreate = createContext();


const Testing3 = (props) => {
  const [res, setRes] = useState([]);
  const [fiixed_fees, setFixed_fees] = useState([]);
  const [collection_fees, setCollection_fees] = useState([]);
  const [shipping_fees, setShipping_fees] = useState([]);
  const [reverse_fees, setReverse_fees] = useState([]);
  const [flipkart_data, setFlipkart_data] = useState([]);


  if (props.data2.length > 0) {
    console.log(props.data2);
  }

  const count = useSelector((state)=>state.counter)
  const dispatch = useDispatch()




  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/flipfix");
        setFixed_fees(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData2() {
      try {
        const response = await axios.get("/api/flipship");
        // //console.log("Response:", response.data);
        setShipping_fees(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData3() {
      try {
        const response = await axios.get("/api/fliprevship");
        // //console.log("Response:", response.data);
        setReverse_fees(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchData4() {
      try {
        const response = await axios.get("/api/flipdata");
        //console.log("Response:", response.data);
        setFlipkart_data(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
    // fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);

  useEffect(() => {
    dispatch(setState(["a",2]))
  },[])

  const step1 = () => {
    let storage = {};
    let Result = [];
    const commision = [];
    let Discount = [];
    const animals = [];
    const arr = [];
    const Q = flipkart_data;
    const W = props.data2;
    console.log(Discount);
    // //console.log("test commission",commision)
    // //console.log("test fixed fees", fiixed_fees)
    //console.log(Q);
    for (let i = 0; i < W.length; i++) {
      const Wl = W[i]["Vendor Name"].toLowerCase();
      const El = W[i]["Brand Name"].toLowerCase();
      const Fl = W[i]["Gender"].toLowerCase();
      const Gl = W[i]["Sub-category"].toLowerCase();
      //console.log("w-category", Wl);

      const paymentRealized = W[i]["Payment Realized"];
      for (let j = 0; j < Q.length; j++) {
        const Sub = Q[j]["Sub_category"].toLowerCase();
        const Hl = Q[j]["Brand_Name"].toLowerCase();
        const Il = Q[j]["Gender"].toLowerCase();
        const Kl = Q[j]["Vendor_Name"].toLowerCase();

        // if ((W[i].Category =="water_dispenser" ) || ((Wl== "pyjama") && (El=="Kids-Girls")) || ((Wl== "shrug") && (El=="Baby-girls"))){

        if (
          (W[i].Category == "pyjama" && W[i].gender == "Kids-Girls") ||
          (W[i].Category == "shrug" && W[i].gender == "Baby-girls") ||
          W[i].Category == "water_dispenser"
        ) {
          //console.log("match");
          packages(i, j, paymentRealized, Q);
          packages1(i, j, paymentRealized, Q);

          break;
        } else {
          // if (((Wl == Ql) &&(El == Dl)) &&  ((W[i].Category !== "pyjama")&&( W[i].gender !=="Kids-Girls")) && ((W[i].Category !=="shrug")&&(W[i].Category!=="Baby-girls")) && (W[i].Category !== "water_dispenser")){

          if (Wl == Kl && El == Hl && Fl == Il && Gl == Sub) {
            console.log("match1=", j);
            packages(i, j, paymentRealized, Q);
            packages2(i, j, paymentRealized, Q);
            break;
          } else {
            //console.log("no match");
          }
        }
      }
    }

    //////////////////////////////////////
    const updatedData = props.data2.map((item, index) => {
      return { ...item, ASP: Result[index], Discount: Discount[index] };
    });
    props.setData2(updatedData);
    //////////////////////////////////////////////

    function packages(i, j, paymentRealized, Q) {
      // ////console.log("j", j)
      const maximumval = Q[j][">1000"];
      console.log(maximumval);
      let c = maximumval * 100;
      let d = 100 - c;
      const asp1 = (paymentRealized / d) * 100;
      const keyName1 = `asp${i}`;
      storage[keyName1] = asp1;

      const keyName2 = `j${i}`;
      storage[keyName2] = j;
      console.log("storage", storage);
    }

    function packages1(i, j, paymentRealized, Q) {
      let find = `asp${i}`;
      let ans = storage[find];

      let findj = `j${i}`;
      let findji = storage[findj];
      const aaa = Q[findji]["0-300"];
      const bb = Q[findji]["300-500"];
      const cc = Q[findji]["500-1000"];
      const ddd = Q[findji][">1000"];
      // const ee = Q[findji]["1000-1500"];
      // const ff = Q[findji]["1500-2500"];
      // const gg = Q[findji][">2500"];
      const hh = Q[findji].Gender;
      const ii = Q[findji]["Sub_category"];

      animals.push(aaa, bb, cc, ddd);
      for (let i = 0; i < animals.length; i++) {
        const val0 = animals[i] * 100;
        const val1 = 100 - val0;
        const aspc = (paymentRealized / val1) * 100;
        arr.push(aspc);
      }
      let minValue = Math.max(...arr);
      // ////console.log("Minimum element is:" + minValue);
      let dd = perFinder1(findji, minValue);
      // ////console.log(dd);

      let val0 = dd * 100;
      let z = Math.round((paymentRealized / (100 - val0)) * 100);
      // ////console.log("final asp:"+ z)

      commision.push(Number(dd));
      //console.log(Number(dd));
      const s = Math.round(newton(paymentRealized, i, commision, j));
      // ////console.log(s);
      Result.push(s);
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      let a2 = props.data2[i].MRP - Result[i];
      let b2 = Math.round((a2 / props.data2[i].MRP) * 100);
      Discount.push(b2);

      // else {
      //   const c = storage[`asp${i}`];
      //   const d =  Math.round(c)
      //  // ////console.log("above 2500",d)
      //   Result.push(d)
      //   let a2 = (props.data2[i].MRP)-(Result[i])
      //   let b2 = Math.round((a2/props.data2[i].MRP)*100)
      //   Discount.push(b2)

      // }
    }

    function packages2(i, j, paymentRealized, Q) {
      let find = `asp${i}`;

      let findj = `j${i}`;
      let findji = storage[findj];
      const aaa = Q[findji]["0-300"];
      const bb = Q[findji]["300-500"];
      const cc = Q[findji]["500-1000"];
      const ddd = Q[findji][">1000"];
      // const ee = Q[findji]["1000-1500"];
      // const ff = Q[findji]["1500-2500"];
      // const gg = Q[findji][">2500"];
      const hh = Q[findji].Gender;
      const ii = Q[findji]["Sub_category"];

      animals.push(aaa, bb, cc, ddd);
      console.log("animalarray", animals);
      for (let i = 0; i < animals.length; i++) {
        const val0 = animals[i] * 100;
        const val1 = 100 - val0;
        const aspc = (paymentRealized / val1) * 100;
        //console.log("animal",aspc)
        arr.push(aspc);
      }
      let minValue = Math.max(...arr);
      console.log("arr", arr);
      console.log("Minimum element is:" + minValue);
      let dd = perFinder(findji, minValue);
      console.log("perFinder", dd);
      commision.push(Number(dd));
      // console.log(Number(dd));

      //   // let val0 = (dd*100);
      //   // let z=  Math.round((paymentRealized/(100-val0))*100)
      //   //// ////console.log("final asp:"+ z)
      //   //Result.push(z)

      const s = Math.round(newton(paymentRealized, i, commision, j));
      console.log("newton raphsons", s);
      Result.push(s);
      //   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      let a2 = props.data2[i].MRP - Result[i];
      let b2 = Math.round((a2 / props.data2[i].MRP) * 100);
      Discount.push(b2);
    }
  };

  const perFinder = (j, min) => {
    const Q = flipkart_data;
    const aaa = Q[j]["0-300"];
    const bb = Q[j]["300-500"];
    const cc = Q[j]["500-1000"];
    const ddd = Q[j][">1000"];
    // const ee = Q[j]["1000-1500"];
    // const ff = Q[j]["1500-2500"];
    // const gg = Q[j][">2500"];
    // ////console.log(gg)
    if (min <= 300 && min >= 0) {
      const a1 = aaa;
      return a1;
    }
    if (min <= 500 && min > 300) {
      const a1 = bb;
      return a1;
    }

    if (min <= 1000 && min > 500) {
      const a1 = cc;
      return a1;
    }
    if (min > 1000) {
      const a1 = ddd;
      return a1;
    }
  };

  const perFinder1 = (j, min) => {
    const Q = flipkart_data;
    const aaa = Q[j]["0-300"];
    const bb = Q[j]["300-500"];
    const cc = Q[j]["500-1000"];
    const ddd = Q[j][">1000"];
    // const ee = Q[j]["1000-1500"];
    // const ff = Q[j]["1500-2500"];

    if (min <= 300 && min >= 0) {
      const a1 = aaa;
      return a1;
    }
    if (min <= 500 && min > 300) {
      const a1 = bb;
      return a1;
    }

    if (min <= 1000 && min > 500) {
      const a1 = cc;
      return a1;
    }

    // if (min <= 1000 && min > 800) {
    //   const a1 = ddd;
    //   return a1;
    // }

    // if (min <= 1500 && min > 1000) {
    //   const a1 = ddd;
    //   return a1;
    // }
    // if (min <= 2500 && min > 1500) {
    //   const a1 = ddd;
    //   return a1;
    // }
    if (min > 1000) {
      const a1 = ddd;
      return a1;
    }
  };
  // useEffect(()=>{
  //  // ////console.log(res)
  // },[res])

  ///////////////////
  /////////////newton raphion/////////////////////

  function newton(payment, i, commision, j) {
    console.log(payment, i, commision, j);
    // Function to calculate the result based on the given values of C6, C8, C10, C11, C12, C13, and C14
    function calculateResult(C6, C8, C10, C12, C13, C14) {
      return C6 - C8 - C10 - C12 - C13 - C14;
    }

    // Function to calculate the derivative of the result with respect to C6
    function calculateDerivative(C6, C7, C8, C9, C10, C12, C13, C14) {
      // Central difference method for numerical derivative
      const h = 0.0001; // Step size
      return (
        (calculateResult(C6 + h, C8, C10, C12, C13, C14) -
          calculateResult(C6 - h, C8, C10, C12, C13, C14)) /
        (2 * h)
      );
    }

    // Function to perform Newton-Raphson method to find the value of C6
    function newtonRaphson(C7, C9, initialGuess, tolerance, targetResult) {
      let C6 = initialGuess;
      let C8, C10, C12, C13, C14, result, derivative;

      do {
        C8 = C7 * C6;
        C10 = calculateC10(C6, j);
        // C11 = calculateC11(C6, C9, j);
        C12 = calculateC12(C6, C9);
        C13 = calculateC13(C9);
        C14 = calculateC14(C6, C9, C7, C8, C10, C12, C13);

        result = calculateResult(C6, C8, C10, C12, C13, C14);
        derivative = calculateDerivative(
          C6,
          C7,
          C8,
          C9,
          C10,

          C12,
          C13,
          C14
        );

        C6 -= (result - targetResult) / derivative;
      } while (Math.abs(result - targetResult) > tolerance);

      return C6;
    }

    // Function to calculate C10 based on C6
    function calculateC10(C6, j) {
      const Q = fiixed_fees;
      console.log("C10", Q[j]["Rate"]);
      return Q[j]["Rate"];
    }

    // Function to calculate C11 based on C6 and C9
    // function calculateC11(C6, C9, j) {
    //   if (C6 <= 750) {
    //     const v = collection_fees[j].Prepaid;
    //     //console.log("C11", v);
    //     return v / (1 - C9);
    //   }
    //   if (C6 > 750) {
    //     const v = collection_fees[j].__EMPTY_2;
    //     //console.log("C11", v);
    //     return (C6 * v) / (1 - C9);
    //   }
    // }

    // Function to calculate C12 based on C6 and C9
    function calculateC12(C6, C9) {
      const s = shipping_fees[j + 1]["National_0-0.5Kg"];
      const newc9 = C9 / 100;
      console.log("c12", s / (1 - C9));
      return s / (1 - newc9);
    }

    // Function to calculate C13 based on C9
    function calculateC13(C9) {
      const l = reverse_fees[j + 1]["National_0-0.5Kg"];
      const newc9 = C9 / 100;
      console.log("c13", (l * C9) / (1 - C9));
      return (l * newc9) / (1 - newc9);
    }

    // Function to calculate C14 based on C6, C9, C7, and other values
    function calculateC14(C6, C9, C7, C8, C10, C12, C13) {
      console.log("c14", (C8 + C10 + C12 + C13) * 0.18);
      console.log("C8", C8);
      return (C8 + C10 + C12 + C13) * 0.18;
    }

    // Example values for C7 and C9
    const C7 = commision[i]; // Example value for C7
    const C9 = props.data2[i]["Customer Returns"]; // Example value for C9
    console.log("C7", C7);
    console.log("C9", C9);
    // Initial guess for C6 and tolerance
    const initialGuess = 1;
    const tolerance = 0.1;

    // Target result
    const targetResult = payment;

    // Finding the value of C6
    const goalSeekResult = newtonRaphson(
      C7,
      C9,
      initialGuess,
      tolerance,
      targetResult
    );

    // Printing the result
    console.log("Goal Seek Result (C6):", goalSeekResult);
    return goalSeekResult;
  }

  return (
    <div>
      <h1 className="flipkart">Flipkart</h1>
      <button id="xyz" onClick={step1}>
        Calc ASP
      </button>
      {/* <Newton data = {res} state2 = {props.data2} ></Newton> */}
      {/* <ContextCreate.Provider value={flipkart_data}>
      {children}
      </ContextCreate.Provider> */}
    </div>
  );
};

export default Testing3;
