import { createSlice,createAction } from "@reduxjs/toolkit";


const counterSlice = createSlice(
    {
        name: "counter",
        initialState : [],
        reducers: {
            setState: (state, action) => {
              return [action.payload]; // or state = action.payload; if you want to replace the entire state
            }
          }
        
    }
)
export const {setState}= counterSlice.actions;
export default counterSlice.reducer