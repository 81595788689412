import React from "react";
import { useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";

const Test = () => {
  const [data2, setData2] = useState([]);
  const [filter1, setFilter1] = useState([]);
  const [filter2, setFilter2] = useState([]);
  const [filter3, setFilter3] = useState([]);
  const [filter4, setFilter4] = useState([]);

  const handleFileUpload2 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2(parsedData);
      // };
    };
  };
  console.log(data2);
  return (
    <div>
      <div className="app2">
        <input
          type="file"
          accept=".xlsx, .xls"
          className="dcd"
          onChange={(e) => {
            handleFileUpload2(e); // Call the handleFileUpload2 function here
          }}
        />

        {/* <Home setDropdown={setDropdown}/> */}
      </div>
      <div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select
            name="pets"
            id="pet-select"
            onChange={(e) => setFilter1(e.target.value)}
          >
            <option value="">--Please choose an option--</option>
            {data2.map((data, index) => (
              <option key={index} value={data["Vendor Name"]}>
                {data["Vendor Name"]}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select" onChange={(e) => setFilter2(e.target.value)}>
            <option value="">--Please choose an option--</option>
            {data2
              .filter((data) => data["Vendor Name"] == filter1)
              .map((data, index) => (
                <option key={index} value={data["Brand Name"]}>
                  {data["Brand Name"]}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select" onChange={(e) => setFilter3(e.target.value)}>
            <option value="">--Please choose an option--</option>
            {data2
              .filter((data) => data["Brand Name"] == filter2)
              .map((data, index) => (
                <option key={index} value={data["Gender"]}>
                  {data["Gender"]}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select" onChange={(e) => setFilter4(e.target.value)}>
            <option value="">--Please choose an option--</option>
            {data2
              .filter((data) => (data["Gender"] == filter3)&& (data["Brand Name"]== filter2))
              .map((data, index) => (
                <option key={index} value={data["Sub-category"]}>
                  {data["Sub-category"]}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select">
            <option value="">--Please choose an option--</option>
            <option value="dog">Dog</option>
            <option value="cat">Cat</option>
            <option value="hamster">Hamster</option>
            <option value="parrot">Parrot</option>
            <option value="spider">Spider</option>
            <option value="goldfish">Goldfish</option>
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select">
            <option value="">--Please choose an option--</option>
            <option value="dog">Dog</option>
            <option value="cat">Cat</option>
            <option value="hamster">Hamster</option>
            <option value="parrot">Parrot</option>
            <option value="spider">Spider</option>
            <option value="goldfish">Goldfish</option>
          </select>
        </div>
        <div>
          <label for="pet-select">Choose a pet:</label>

          <select name="pets" id="pet-select">
            <option value="">--Please choose an option--</option>
            <option value="dog">Dog</option>
            <option value="cat">Cat</option>
            <option value="hamster">Hamster</option>
            <option value="parrot">Parrot</option>
            <option value="spider">Spider</option>
            <option value="goldfish">Goldfish</option>
          </select>
        </div>
      </div>

      {data2.length > 0 && (
        <div className="scroll-container">
          <table>
            <thead>
              <tr>
                {Object.keys(data2[0]).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data2.map((row, rowIndex) => (
                <tr key={row.id || rowIndex}>
                  {Object.values(row).map((value, valueIndex) => (
                    <td key={valueIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Test;
